<div class="main-property">
  <div class="property-main-container no-property-block-gap">
    <div class="property-block">
      <div class="property-box">
        <app-developer-json-view *ngIf="uiService.LocationOneFlag" [myFormGroup]="PropertyDetailsLocationOneForm"
          [mode]="'PropertyDetailsLocationOneForm'"></app-developer-json-view>
        <app-developer-json-view *ngIf="uiService.LocationTwoFlag" [myFormGroup]="PropertyDetailsLocationTwoForm"
          [mode]="'PropertyDetailsLocationTwoForm'"></app-developer-json-view>
        <app-developer-json-view *ngIf="uiService.LocationThreeFlag" [myFormGroup]="PropertyDetailsLocationThreeForm"
          [mode]="'PropertyDetailsLocationThreeForm'"></app-developer-json-view>
        <div class="location-tabs">
          <div class="location-tabs-container">
            <div class="propertysection-title no-top-space">
              <p class="title-text">
                Property Details
              </p>
            </div>
            
            <!-- hiding the e2v link-->
            <!-- <div class="e2vClass">
              <a [href]="e2vLinkUrl" target="_blank">E2V</a>
            </div> -->

            <div class="location-tabs-list">
              <div class="location-tabs-item" (click)="_doSelectLocationTab('one', 'LocationOne')"
                [ngClass]="{'active':uiService.LocationOneFlag, 'disabled': !uiService.LocationOneTab}">
                <h5 class="location-tabs-title">
                  Location 1
                </h5>
              </div>
              <div class="location-tabs-item" (click)="_doSelectLocationTab('two', 'LocationTwo')"
                [ngClass]="{'active':uiService.LocationTwoFlag, 'disabled': !uiService.LocationTwoTab}">
                <h5 class="location-tabs-title" *ngIf="uiService.addedLocations?.length > 1">
                  Location 2
                </h5>
              </div>
              <div class="location-tabs-item" (click)="_doSelectLocationTab('three', 'LocationThree')"
                [ngClass]="{'active':uiService.LocationThreeFlag, 'disabled': !uiService.LocationThreeTab}">
                <h5 class="location-tabs-title" *ngIf="uiService.addedLocations?.length > 2">
                  Location 3
                </h5>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <form [formGroup]="PropertyDetailsLocationOneForm" *ngIf="uiService.LocationOneFlag">
      <div class="location-tabs-content" formArrayName="LocationOne">

        <div class="property-block" *ngIf="(dataMappingService.businessFlags[BusinessAttribute.APARTMENTS])
        || (dataMappingService.businessFlags[BusinessAttribute.RESTAURANTS])">
          <div class="property-box">
            <div>
              <div class="location-content">
                <h4 class="location-content-title">
                  Location 1 {{dataService.BPLocations[0].Address.Line1}} ,
                  {{dataService.BPLocations[0].Address.City}} ,
                  {{dataService.BPLocations[0].Address.StateDesc}}
                </h4>
              </div>
              <div class="field-box-w276" *ngIf="dataMappingService.businessFlags[BusinessAttribute.APARTMENTS]">
                <label class="field-label-w276">Apartment Buildings Endorsement</label>
                <div class="toggle-group-w276">
                  <div value="Y" class="toggle-button-left-w138" [ngClass]="
                  apartmentLocationOneEndorsementSelectedVal == 'Y'
                      ? 'selected-button'
                      : 'unselected-button'
                    " (click)="locationOneApartmentBuildingEndorsementValChange(true, 0)">
                    Yes
                  </div>
                  <div value="N" class="toggle-button-right-w138" [ngClass]="
                  apartmentLocationOneEndorsementSelectedVal == 'N'
                        ? 'selected-button'
                        : 'unselected-button'
                    " (click)="locationOneApartmentBuildingEndorsementValChange(false, 0)">
                    No
                  </div>
                </div>
              </div>
              <div class="field-box-w276" *ngIf="dataMappingService.businessFlags[BusinessAttribute.RESTAURANTS]">
                <label class="field-label-w276">Restaurant Endorsement
                  <div class="link-form-tooltip">
                    <img src="../../../assets/img/icon-info.png">
                    <span class="link-form-tooltiptext">
                      <a class="link-form-link" href='../../../assets/docs/BP 07 78 (07-13) Restaurants.pdf'
                        target="_blank" rel="noopener noreferrer">
                        Form BP 07 78
                      </a>
                    </span>
                  </div>
                </label>
                <div class="toggle-group-w276">
                  <div value="Y" class="toggle-button-left-w138" [ngClass]="
                  restaurantLocationOneEndorsementSelectedVal == 'Y'
                          ? 'selected-button'
                          : 'unselected-button'
                    " (click)="restaurantEndorsementValChange(true, 0)">
                    Yes
                  </div>
                  <div value="N" class="toggle-button-right-w138" [ngClass]="
                  restaurantLocationOneEndorsementSelectedVal == 'N'
                          ? 'selected-button'
                          : 'unselected-button'
                      " (click)="restaurantEndorsementValChange(false, 0)">
                    No
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="property-block" *ngFor="let building of LocationsOneBuildings?.controls; let i = index">
          <div class="property-box" formGroupName="{{i}}">
            <div>
              <div class="location-content">
                <h4 class="location-content-title">
                  Location 1 {{dataService.BPLocations[0].Address.Line1}} , {{dataService.BPLocations[0].Address.City}}
                  , {{dataService.BPLocations[0].Address.StateDesc}}
                </h4>
                <div class="propertysection-title">
                  <p class="title-text">
                    Building {{i+1}}
                  </p>
                </div>
                <div class="row-frame-h62">
                  <div class="field-main-prop">
                    <label class="field-label-readonly-prop">Coverage Basis</label>
                    <label class="field-value-prop">Replacement Cost</label>
                  </div>

                  <div class="field-box-w276"
                    *ngIf="(dataMappingService.businessFlags[BusinessAttribute.CONTRACTORS] && uiService.isInsuredSmallContractorSelectedVal!='Y')">
                    <label class="field-label-w276 ">Building Limit</label>
                    <input class="field-input-w276" formControlName="BuildingLimitInsurance"
                      placeholder="Amount in dollars" autocomplete="off" currencyNumericInput maxDigits="9"
                      id="property-details-building-limit" min="1" max="32"
                      (change)="validateBasicBuildingLimit($event.target.value, i, building)"
                      (keyup)="ShowFields($event.target.value, i, building)" />
                    <div *ngIf="
                        building.get('BuildingLimitInsurance').invalid &&
                          (building.get('BuildingLimitInsurance').dirty ||
                          building.get('BuildingLimitInsurance').touched) && 
                          building.get('BuildingLimitInsurance').errors.buildingLimit
                          " class="alert">
                      Building limit cannot be less than $1,000
                    </div>
                  </div>
                  <div class="field-box-w276" *ngIf="dataMappingService.businessFlags[BusinessAttribute.CONTRACTORS]">
                    <label class="field-label-w276 ">Business Personal Property Limit</label>
                    <input class="field-input-w276" formControlName="BusinssPersonalPropLmt"
                      placeholder="Amount in dollars" autocomplete="off" currencyNumericInput maxDigits="9"
                      id="property-details-business-personal-property-limit" min="1" max="32"
                      (change)="validateBasicBusinessPersonalPropertyLimit($event.target.value, i, 'PropertyDetailsLocationOneForm', building)" />
                    <div *ngIf="
                          building.get('BusinssPersonalPropLmt').invalid &&
                          (building.get('BusinssPersonalPropLmt').dirty ||
                          building.get('BusinssPersonalPropLmt').touched)
                          " class="alert">
                      Business Personal Property must not exceed $5,000
                    </div>
                  </div>
                  <div class="field-box-w276"
                    *ngIf="!(dataMappingService.businessFlags[BusinessAttribute.CONTRACTORS])">
                    <label
                      [ngClass]="(building.get('buildinglimitMandatoryFlag').value)?'field-label-w276 required-field':'field-label-w276'">Building
                      Limit</label>
                    <input class="field-input-w276" formControlName="BuildingLimitInsurance"
                      placeholder="Amount in dollars" autocomplete="off" currencyNumericInput maxDigits="9"
                      id="property-details-building-limit" min="1" max="32"
                      (change)="validateBasicBuildingLimit($event.target.value, i, building)"
                      (keyup)="ShowFields($event.target.value, i, building)" />
                    <div *ngIf="
                    building.get('BuildingLimitInsurance').invalid &&
                      (building.get('BuildingLimitInsurance').dirty ||
                      building.get('BuildingLimitInsurance').touched) && 
                      building.get('BuildingLimitInsurance').errors.buildingLimit
                      " class="alert">
                      Building limit cannot be less than $1,000
                    </div>
                  </div>
                  <div class="field-box-w276"
                    *ngIf="!(dataMappingService.businessFlags[BusinessAttribute.CONTRACTORS])">
                    <label
                      [ngClass]="(!building.get('buildinglimitMandatoryFlag').value)?'field-label-w276 required-field':'field-label-w276'">Business
                      Personal Property Limit</label>
                    <input class="field-input-w276" formControlName="BusinssPersonalPropLmt"
                      placeholder="Amount in dollars" autocomplete="off" currencyNumericInput maxDigits="9"
                      id="property-details-business-personal-property-limit" min="1" max="32"
                      (change)="validateBasicBusinessPersonalPropertyLimit($event.target.value, i, 'PropertyDetailsLocationOneForm', building)" />
                    <div
                      *ngIf=" onIntrestChangeMandatoryBppLocationOne &&  building.get('BusinssPersonalPropLmt').invalid || (building.get('BusinssPersonalPropLmt').invalid &&
                      (building.get('BusinssPersonalPropLmt').dirty ||
                      building.get('BusinssPersonalPropLmt').touched) && building.get('BusinssPersonalPropLmt').errors.businssPersonalPropLmt )"
                      class="alert">
                      Business Personal Property minimum $10,000
                    </div>
                  </div>
                  <div class="field-box-w276">
                    <label class="field-label-w276 required-field">Property Deductible</label>
                    <select class="field-input-w276" name="type" formControlName="OptionalPropertyDedu"
                      id="property-details-deductible">
                      <option class="dropdown-text" style="display:none"></option>
                      <option class="dropdown-text" [value]="deductible" *ngFor="let deductible of deductibleOptions">
                        {{ deductible }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="row-frame-h62" style="height: 84px;padding-top: 32px">
                <div class="field-box-w276">
                  <label class="field-label-w276 required-field">Year of Construction</label>
                  <input class="field-input-w276" formControlName="YearBuilt" id="property-details-year-of-construction"
                    (change)="validateYearOfConstructionLimit($event.target.value, building)" (input)="yearBuiltValueCheck($event.target.value,'PropertyDetailsLocationOneForm',i)" minlength="0"
                    maxlength="4" onlyNumber required />
                  <div *ngIf="
                      building.get('YearBuilt').invalid &&
                        (building.get('YearBuilt').dirty ||
                        building.get('YearBuilt').touched)
                      " class="alert">
                    Required
                  </div>
                  <div *ngIf="building.get('yearofBuiltFailedFlag').value" class="alert">
                    Year of Built Should not be greater than Current year
                  </div>
                </div>

                <div class="field-box-w276">
                  <label class="field-label-w276 required-field">Construction Type</label>
                  <select class="field-input-w276" name="type" formControlName="ConstructionType"
                    id="property-details-construction-type">
                    <option class="dropdown-text" style="display:none"></option>
                    <option class="dropdown-text" [value]="construction"
                      *ngFor="let construction of dropDownService.constructionOptions">
                      {{ construction }}
                    </option>
                  </select>
                </div>

                <div class="field-box-w276">
                  <label class="field-label-w276 required-field">Distance from Fire Station</label>
                  <input class="field-input-w276" minlength="1" maxlength="100" onlyNumber [allowDecimal]="true"
                    formControlName="DistanceFromFireStation" (keyup)="_doPrefillOtherValues('one', building)"
                    id="property-details-distance-fire-station" required *ngIf="i == 0" />
                  <input class="field-input-w276" minlength="1" maxlength="100" onlyNumber [allowDecimal]="true"
                    formControlName="DistanceFromFireStation" id="property-details-distance-fire-station" required
                    *ngIf="i != 0" readonly />
                  <div class="field-box-w276" style="height: 18px">
                    <p class="hint">in miles</p>
                  </div>
                  <div *ngIf="
                  building.get('DistanceFromFireStation').invalid &&
                        (building.get('DistanceFromFireStation').dirty ||
                        building.get('DistanceFromFireStation').touched)
                      " class="alert">
                    Required
                  </div>
                </div>
                <div class="field-box-w276">
                  <label class="field-label-w276 required-field">Distance from Fire Hydrant</label>
                  <input class="field-input-w276" minlength="1" maxlength="100" onlyNumber
                    formControlName="DistToNearestHydrant" id="property-details-distance-fire-hydrant"
                    (keyup)="_doPrefillDFHValues('one', building)" required *ngIf="i == 0" />
                  <input class="field-input-w276" minlength="1" maxlength="100" onlyNumber
                    formControlName="DistToNearestHydrant" id="property-details-distance-fire-hydrant" required
                    *ngIf="i != 0" readonly />
                  <div class="field-box-w276" style="height: 18px">
                    <p class="hint">in feet</p>
                  </div>
                  <div *ngIf="
                  building.get('DistToNearestHydrant').invalid &&
                        (building.get('DistToNearestHydrant').dirty ||
                        building.get('DistToNearestHydrant').touched)
                      " class="alert">
                    Required
                  </div>
                </div>
              </div>
            </div>
            <div class="location-content-bottom">
              <div class="row-frame-h62">
                <div class="field-box-w276" style="width: 140px">
                  <label class="field-label-readonly-prop" style="width: 106px">Protection Class</label>
                  <label class="field-value-prop">{{ building.get("ppc").value }}</label>
                </div>
                <div class="field-box-w276" style="width: 676px" *ngIf="uiService.LocationOneYearofConstruction[i].buildingCheck">
                  <label style="width: 676px">Has the building's roof, plumbing, water heater, heating and electrical,
                    been updated in the last 20 years?</label>
                  <div class="toggle-group-w288">
                    <div value="Y" class="toggle-button-left-w144" [ngClass]="
                        building.get('buildingroofselectedVal').value == 'Y'
                          ? 'selected-button'
                          : 'unselected-button'
                      " (click)="onbuildingroofclick(true, building)">
                      Yes
                    </div>
                    <div value="N" class="toggle-button-right-w144" [ngClass]="
                    building.get('buildingroofselectedVal').value == 'N'
                          ? 'selected-button'
                          : 'unselected-button'
                      " (click)="onbuildingroofclick(false, building)">
                      No
                    </div>
                  </div>
                </div>

                <div class="field-box-w276" style="width: 312px"
                  *ngIf="building.get('buildingroofselectedVal').value == 'Y'">
                  <label class="field-label-w376 required-field">What is the year of the oldest update?</label>
                  <input style="width: 312px" class="field-input-w276" onlyNumber formControlName="yearOfOldestUpdate"
                    id="property-year-of-oldest-update" minlength="0" maxlength="4" />
                </div>
              </div>
              <div class="row-frame-h62" style="height: 98px;">
                <div class="check-group-box">
                  <label class="field-label-w276">Local Alarm</label>

                  <div class="check-group-button">
                    <div class="check-group-field">
                      <input class="checkboxes" type="checkbox" id="localAlarmFireChecked"
                        formControlName="fireAlarmALocalAlarm" />
                      <label>Fire</label>
                    </div>
                    <div class="check-group-field">
                      <input class="checkboxes" type="checkbox" id="localAlarmBurglarChecked"
                        formControlName="CrmProtAlmALocalAlm" />
                      <label>Burglar</label>
                    </div>
                  </div>
                </div>
                <div class="check-group-box">
                  <label class="field-label-w276">Central Station Alarm</label>

                  <div class="check-group-button">
                    <div class="check-group-field">
                      <input class="checkboxes" type="checkbox" id="centralStationAlarmFireChecked"
                        formControlName="fireAlarmACenStnAlarm" />
                      <label>Fire</label>
                    </div>
                    <div class="check-group-field">
                      <input class="checkboxes" type="checkbox" id="centralStationAlarmBurglarChecked"
                        formControlName="CrmProtAlmACenStAlm" />
                      <label>Burglar</label>
                    </div>
                  </div>
                </div>
                <div class="check-group-box">
                  <label class="field-label-w276">Smoke Detectors</label>

                  <div class="check-group-button">
                    <div class="check-group-field">
                      <input class="checkboxes" type="checkbox" id="SmokeDetectorsBatteryChecked"
                        formControlName="fireAlarmABatopSmkDet" />
                      <label>Battery</label>
                    </div>
                    <div class="check-group-field">
                      <input class="checkboxes" type="checkbox" id="SmokeDetectorsHardwiredChecked"
                        formControlName="fireAlarmAHrdwrSmkDet" />
                      <label>Hardwired</label>
                    </div>
                  </div>
                </div>
                <div class="field-box-w276">
                  <label class="field-label-w276">Building is 100% Sprinklered
                    <div class="sprinkle_tooltip">
                      <img src="../../../assets/img/icon-info.png">
                      <span class="sprinkle_tooltiptext">
                        A sprinklered building has fully functional fire sprinkler coverage throughout the entire
                        building
                        and provides
                        both life safety and protection to the facility and its assets.
                      </span>
                    </div>
                  </label>
                  <div class="toggle-group-w288" style="width: 276px">
                    <div value="Y" class="toggle-button-left-w144" [ngClass]="
                    building.get('autoSprinkleredSystem').value == 'Y'
                          ? 'selected-button'
                          : 'unselected-button'
                      " (click)="sprinkleredSelectedCheck(true, building)">
                      Yes
                    </div>
                    <div value="N" class="toggle-button-right-w144" [ngClass]="
                    building.get('autoSprinkleredSystem').value == 'N'
                          ? 'selected-button'
                          : 'unselected-button'
                      " (click)="sprinkleredSelectedCheck(false,building)">
                      No
                    </div>
                  </div>
                </div>
              </div>

              <div class="row-frame-h62">
                <div class="field-box-w276">
                  <label class="field-label-w276 required-field">Damage To Premises Rented To You</label>
                  <select class="field-input-w276" formControlName="dmgPremRentYouLimit"
                    id="optional-coverages-damage-to-premises">
                    <option class="dropdown-text" value="">Select</option>
                    <option class="dropdown-text" [ngValue]="damageRented"
                      *ngFor="let damageRented of dropDownService.damageRentedLimitOptions">
                      {{ damageRented }}
                    </option>
                  </select>
                </div>
                <div class="field-box-w276" style="width: 507px;">
                  <label class="field-label-w276 required-field">Interest</label>
                  <select class="field-input-w276" style="width: 507px;" formControlName="Interest"
                    (change)="onInterestChange($event.target.value, i, 'PropertyDetailsLocationOneForm')"
                    id="property-details-interest">
                    <option class="dropdown-text" value="">Select</option>
                    <option class="dropdown-text" *ngFor="let interest of processedInterestOptions"
                      [ngValue]="interest">
                      {{ interest }} </option>
                  </select>
                  <div *ngIf="
                      building.get('Interest').invalid &&
                      (building.get('Interest').dirty ||

                      building.get('Interest').touched)
                    " class="alert">
                    Required
                  </div>
                </div>

                <div class="field-box-w276" *ngIf="building.get('BuildingLimitInsurance').value">
                  <label [ngClass]="(buildingSquareFootageMandatoryFlagLocationOne)?'field-label-w276 required-field':'field-label-w276'">Total Square Footage</label>
                  <input class="field-input-w276" minlength="1" maxlength="15" onlyNumber
                    formControlName="BuildingSquareFootage" id="property-details-total-square-footage" (keyup)="_doShowSqrFtValidation(building)" (input)="onSquareFootageChange(building)" />
                  <div *ngIf="building.get('totalSquareFootageFlag').value" class="alert">Square Footage Cannot Be 0</div>
                </div>
              </div>

              <div class="row-frame-h62" *ngIf="building.get('BuildingLimitInsurance').value">
                <div class="field-box-w276">
                  <label class="field-label-w276 required-field">Total Number of Units</label>
                  <input class="field-input-w276" minlength="1" maxlength="15" onlyNumber
                    formControlName="NumberOfUnits" id="property-details-total-number-of-units"
                    (keyup)="onTotalNumberOfUnitsChange(building)" />
                  <div *ngIf="building.get('NumberOfUnits').invalid &&
                    building.get('NumberOfUnits').errors.numberOfUnits" class="alert">
                    Total Number of Units must be greater than zero
                  </div>
                  <div *ngIf="!building.get('sumOfUnitsValidFlag').value" class="alert-numUnits">
                    <!-- Required -->
                    Total Number of Units must equal number of Owner-Occupied/Vacant/Leased Units
                  </div>

                </div>

                <div class="field-box-w276">
                  <label class="field-label-w276 required-field">Number of Owner-Occupied Units</label>
                  <input class="field-input-w276" minlength="1" maxlength="15" onlyNumber
                    (change)="calculateSumOfUnits(building)" formControlName="NumberOccupiedByOwners"
                    id="property-details-number-of-owner-occupied-units" />
                  <div *ngIf="
                      building.get('NumberOccupiedByOwners').invalid &&
                      (building.get('NumberOccupiedByOwners').dirty ||
                      building.get('NumberOccupiedByOwners').touched)
                    " class="alert">
                    Required
                  </div>
                </div>

                <div class="field-box-w276">
                  <label class="field-label-w276 required-field">Number of Vacant Units</label>
                  <input class="field-input-w276" minlength="1" maxlength="15" onlyNumber formControlName="NumberVacant"
                    id="property-details-number-vacant-units" (change)="calculateLeasedToOthers(building)" />
                  <div *ngIf="
                       building.get('NumberVacant').invalid &&
                       (building.get('NumberVacant').dirty ||
                       building.get('NumberVacant').touched)
                     " class="alert">
                    Required
                  </div>
                </div>

                <div class="field-box-w276">
                  <label class="field-label-w276 required-field">Number Leased to Others</label>
                  <input class="field-input-w276" minlength="1" maxlength="15" onlyNumber
                    formControlName="NumberLeasedToOthers" id="property-details-number-leased-to-others"
                    (change)="calculateSumOfUnits(building)" />
                  <div *ngIf="
                      building.get('NumberLeasedToOthers').invalid &&
                      (building.get('NumberLeasedToOthers').dirty ||
                      building.get('NumberLeasedToOthers').touched)
                    " class="alert">
                    Required
                  </div>
                </div>
              </div>


              <div class="field-box-w276"
                *ngIf="((dataMappingService.businessFlags[BusinessAttribute.PROCESSING_AND_SERVICE_RISKS])&&(uiService.processingPhotoService71899Flag) && (dataMappingService.originalClassCodeObj.classcode=='71899'))">
                <label class=" field-label-w276">Photography Endorsement</label>
                <div class="toggle-group-w276">
                  <div value="Y" class="toggle-button-left-w138"
                    [ngClass]="building.get('psPhotoServiceSelectedVal').value == 'Y' ? 'selected-button' : 'unselected-button'"
                    (click)="onPsPhotoServiceClick(true, building)"> YES </div>
                  <div value="N" class="toggle-button-right-w138"
                    [ngClass]="building.get('psPhotoServiceSelectedVal').value == 'N' ? 'selected-button' : 'unselected-button'"
                    (click)="onPsPhotoServiceClick(false,building)"> NO </div>
                </div>
              </div>
              <div class="field-box-w276"
                *ngIf="((dataMappingService.businessFlags[BusinessAttribute.OFFICE])&&(uiService.classcodeOffice64181Flag) && (dataMappingService.originalClassCodeObj.classcode=='64181' || dataMappingService.originalClassCodeObj.classcode=='64191') && (dataMappingService.originalClassCodeObj.description!='Veterinarians Office - Condominium - Office - Lessors Risk Only' && dataMappingService.originalClassCodeObj.description!='Veterinarians Office - Lessors Risk Only'))">
                <label class="field-label-w276">Veterinarian Endorsement</label>
                <div class="toggle-group-w276">
                  <div value="Y" class="toggle-button-left-w138"
                    [ngClass]="building.get('veterinarProfLiab').value == 'Y' ? 'selected-button' : 'unselected-button'"
                    (click)="onveterinarianEndorsementClick(true,building)"> YES </div>
                  <div value="N" class="toggle-button-right-w138"
                    [ngClass]="building.get('veterinarProfLiab').value == 'N' ? 'selected-button' : 'unselected-button'"
                    (click)="onveterinarianEndorsementClick(false,building)"> NO </div>
                </div>
              </div>
              <div class="field-box-w276" *ngIf="building.get('veterinarProfLiab').value == 'Y'">
                <label class="field-label-w276 "># of Veterinarians</label>
                <input class="field-input-w276" formControlName="numberOfVeterinarian" minlength="1" maxlength="20"
                  onlyNumber id="optional-coverages-no-full-time-employees" (input)="onVeterinarianChange(building)" />
                <div *ngIf="building.get('numberOfVeterinarianFailFlag').value" class="alert"> Number of Veterinarian
                  should be great than zero </div>
              </div>
              <!--Processing Service Starts -->
              <div class="field-box-w276"
                *ngIf="((dataMappingService.businessFlags[BusinessAttribute.PROCESSING_AND_SERVICE_RISKS]) && (uiService.processingProcessService7195271332Flag) && (dataMappingService.originalClassCodeObj.classcode=='71952' || dataMappingService.originalClassCodeObj.classcode=='71332'))">
                <label class="field-label-w276">Barbers/Beautician Professional Coverage</label>
                <div class="toggle-group-w276">
                  <div value="Y" class="toggle-button-left-w138"
                    [ngClass]="building.get('psBarberBeauticianSelectedVal').value == 'Y' ? 'selected-button' : 'unselected-button'"
                    (click)="onPsBarberBeauticianClick(true,building)">YES</div>
                  <div value="N" class="toggle-button-right-w138"
                    [ngClass]="building.get('psBarberBeauticianSelectedVal').value == 'N' ? 'selected-button' : 'unselected-button'"
                    (click)="onPsBarberBeauticianClick(false,building)">NO</div>
                </div>
              </div>
              <div class="field-box-w276"
                *ngIf="dataMappingService.businessFlags[BusinessAttribute.PROCESSING_AND_SERVICE_RISKS] && building.get('psBarberBeauticianSelectedVal').value == 'Y' && uiService.processingBarberService71332Flag && (dataMappingService.originalClassCodeObj.classcode=='71332')">
                <label class="field-label-w276 required-field">Full Time Barber </label>
                <input class="field-input-w276" minlength="1" onlyNumber formControlName="noOfFullTimeBarbers"
                  required />
              </div>
              <div class="field-box-w276"
                *ngIf="dataMappingService.businessFlags[BusinessAttribute.PROCESSING_AND_SERVICE_RISKS] && building.get('psBarberBeauticianSelectedVal').value == 'Y' && uiService.processingBarberService71332Flag && (dataMappingService.originalClassCodeObj.classcode=='71332')">
                <label class="field-label-w276 required-field">Part Time Barber </label>
                <input class="field-input-w276" minlength="1" onlyNumber formControlName="noOfPartTimeBarbers"
                  required />
              </div>
              <div class="field-box-w276"
                *ngIf="dataMappingService.businessFlags[BusinessAttribute.PROCESSING_AND_SERVICE_RISKS] && building.get('psBarberBeauticianSelectedVal').value == 'Y' && uiService.processingBeauticianService71952Flag && (dataMappingService.originalClassCodeObj.classcode=='71952')">
                <label class="field-label-w276 required-field">Full Time Beauticians</label>
                <input class="field-input-w276" minlength="1" onlyNumber formControlName="noOfFlTmBuPrEnPf" required />
              </div>
              <div class="field-box-w276"
                *ngIf="dataMappingService.businessFlags[BusinessAttribute.PROCESSING_AND_SERVICE_RISKS] && building.get('psBarberBeauticianSelectedVal').value == 'Y' && uiService.processingBeauticianService71952Flag && ((dataMappingService.originalClassCodeObj.classcode=='71952'))">
                <label class="field-label-w276 required-field">Part Time Beauticians</label>
                <input class="field-input-w276" minlength="1" onlyNumber formControlName="noOfPtTmBuPrEnPf" required />
              </div>
              <div class="field-box-w276"
                *ngIf="dataMappingService.businessFlags[BusinessAttribute.RESTAURANTS] || dataMappingService.businessFlags[BusinessAttribute.CONVENIENCE] && uiService.convenience09331Flag && dataMappingService.originalClassCodeObj.classcode=='09331' ">
                <label class="field-label-w276 required-field">Gross Sales <div class="gross-sales-tooltip">
                    <img src="../../../assets/img/icon-info.png">
                    <span class="gross-sales-tooltiptext"> Gross sales includes any/all convenience items and restaurant
                      sales </span>
                  </div>
                </label>
                <input class="field-input-w276" placeholder="Amount in dollars" autocomplete="off" currencyInput min="1"
                  max="32" maxDigits="9" formControlName="AnualGrossSales" required />
              </div>
              <div class="field-box-w276" *ngIf="dataMappingService.businessFlags[BusinessAttribute.CONTRACTORS]">
                <label class="field-label-w276 required-field">Payroll <div class="payroll-tooltip">
                    <img src="../../../assets/img/icon-info.png">
                    <span class="payroll-tooltiptext"> Total payroll includes: <br>
                      <br> 1) {{policyStartPayroll}} for each Executive Officer, Individual Insured, or Co-partner <br>
                      2) Actual payroll for employee (full-time, part-time, temp) excluding clerical <br> 3) Any Leased
                      workers <br> 4) Any payments to uninsured subcontractors </span>
                  </div>
                </label>
                <input class="field-input-w276" placeholder="Amount in dollars" autocomplete="off" currencyInput min="1" max="32" maxDigits="9" formControlName="totalPayroll" id="liability-details-total-payroll" required (change)="liabilityPayrollValidadation(building)" />
                <div *ngIf="building.get('totalPayroll').invalid &&
                        (building.get('totalPayroll').dirty ||
                        building.get('totalPayroll').touched) &&
                        !building.get('isLiabilityPayrollNotValid').value" class="alert"> Required </div>
                <!-- Show appropriate info based upon if selected date is before or after the margin date -->
                <div *ngIf="building.get('isLiabilityPayrollNotValid').value && uiService.isDateAfter &&
                        building.get('totalPayroll').errors?.totalPayroll" class="alert"> Payroll must be equal to or greater than $40,000. </div>
                <div *ngIf="building.get('isLiabilityPayrollNotValid').value && !uiService.isDateAfter &&
                        building.get('totalPayroll').errors?.totalPayroll" class="alert"> Payroll must be equal to or greater than $35,700. </div>
              </div>
            </div>

            <!--Mortgage Code-->
            <div class="mortgage-container" formArrayName="mortgageInfo">
              <div class="mortgage-row" *ngFor="let x of LocationsOneBuildings.controls[i].controls['mortgageInfo'].controls;index as mortgageIndex">
                <div class="mortgage-row-container" formGroupName="{{mortgageIndex}}">
                  <div style="width: 276px;">
                    <label class="mortgage-lbl required-field">Mortgage holder name</label>
                    <input class="mortgage-input" type="text" maxlength="80" required formControlName="mortgageHolderName" />
                  </div>
                  <div style="width: 200px;">
                    <label class="mortgage-lbl required-field">Street</label>
                    <input class="mortgage-input" type="text" maxlength="80" required formControlName="street"/>
                  </div>
                  <div>
                    <label class="mortgage-lbl required-field">City</label>
                    <input class="mortgage-input" type="text" maxlength="30" required formControlName="city"/>
                  </div>
                  <div>
                    <label class="mortgage-lbl required-field">State</label>
                    <input class="mortgage-input" type="text" maxlength="30" required formControlName="stateDesc"/>
                  </div>
                  <div>
                    <label class="mortgage-lbl required-field">Zip</label>
                    <input class="field-input-w276" minlength="0" maxlength="5" value="" onlyNumber required formControlName="zip" (change)=fetchByZipCode(x)/>                 </div>
                  <div class="delete-icon-container">
                    <i aria-label="Delete" class="material-icons" title="Remove mortgagee" (click)="onMortgageDelete(i,mortgageIndex,'PropertyDetailsLocationOneForm')">delete</i>
                  </div>
                </div>
              </div>
            </div>
            <div class="addRemoveButtons">
              <div class="row-frame-h40" style="padding-top: 30px; width: 10px;">
                <button mat-stroked-button="mat-stroked-button" class="add-building-button" color="warn"
                  (click)="AddLocationOneBuilding(i)" *ngIf="LocationsOneBuildings?.controls.length < 3">
                  Add Building
                </button>
                <button style="width:270px" mat-stroked-button="mat-stroked-button" class="add-building-button" color="warn"
                (click)="onAddMortgageInfo('PropertyDetailsLocationOneForm',i)" [disabled]="LocationsOneBuildings.controls[i].controls['mortgageInfo'].length>1">
                  Add Mortgage Holder Information
                </button>
              </div>
              
              <div class="removebutton">
                <button mat-stroked-button="mat-stroked-button" *ngIf="LocationsOneBuildings?.controls.length > 1"
                  class="remove-building-button" color="warn" (click)="RemoveLocationOneBuilding(i)">
                  Remove Building
                  <mat-icon>delete_outline</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <form [formGroup]="PropertyDetailsLocationTwoForm" *ngIf="uiService.LocationTwoFlag">
      <div class="location-tabs-content" formArrayName="LocationTwo">

        <div class="property-block" *ngIf="(dataMappingService.businessFlags[BusinessAttribute.APARTMENTS])
        || (dataMappingService.businessFlags[BusinessAttribute.RESTAURANTS])">
          <div class="property-box">
            <div>
              <div class="location-content">
                <h4 class="location-content-title">
                  Location 2 {{dataService.BPLocations[1].Address.Line1}} ,
                  {{dataService.BPLocations[1].Address.City}} ,
                  {{dataService.BPLocations[1].Address.StateDesc}}
                </h4>
              </div>
              <div class="field-box-w276" *ngIf="dataMappingService.businessFlags[BusinessAttribute.APARTMENTS]">
                <label class="field-label-w276">Apartment Buildings Endorsement</label>
                <div class="toggle-group-w276">
                  <div value="Y" class="toggle-button-left-w138" [ngClass]="
                  apartmentLocationTwoEndorsementSelectedVal == 'Y'
                      ? 'selected-button'
                      : 'unselected-button'
                      " (click)="locationOneApartmentBuildingEndorsementValChange(true, 1)">
                    Yes
                  </div>
                  <div value="N" class="toggle-button-right-w138" [ngClass]="
                  apartmentLocationTwoEndorsementSelectedVal == 'N'
                        ? 'selected-button'
                        : 'unselected-button'
                    " (click)="locationOneApartmentBuildingEndorsementValChange(false, 1)">
                    No
                  </div>
                </div>
              </div>
              <div class="field-box-w276" *ngIf="dataMappingService.businessFlags[BusinessAttribute.RESTAURANTS]">
                <label class="field-label-w276">Restaurant Endorsement
                  <div class="link-form-tooltip">
                    <img src="../../../assets/img/icon-info.png">
                    <span class="link-form-tooltiptext">
                      <a class="link-form-link" href='../../../assets/docs/BP 07 78 (07-13) Restaurants.pdf'
                        target="_blank" rel="noopener noreferrer">
                        Form BP 07 78
                      </a>
                    </span>
                  </div>
                </label>
                <div class="toggle-group-w276">
                  <div value="Y" class="toggle-button-left-w138" [ngClass]="
                  restaurantLocationTwoEndorsementSelectedVal == 'Y'
                          ? 'selected-button'
                          : 'unselected-button'
                    " (click)="restaurantEndorsementValChange(true, 1)">
                    Yes
                  </div>
                  <div value="N" class="toggle-button-right-w138" [ngClass]="
                  restaurantLocationTwoEndorsementSelectedVal == 'N'
                          ? 'selected-button'
                          : 'unselected-button'
                      " (click)="restaurantEndorsementValChange(false, 1)">
                    No
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="property-block" *ngFor="let building of LocationsTwoBuildings?.controls; let i = index">
          <div class="property-box" formGroupName="{{i}}">
            <div>
              <div class="location-content">
                <h4 class="location-content-title" *ngIf="dataService.BPLocations.length > 1">
                  Location 2 {{dataService.BPLocations[1].Address.Line1}} , {{dataService.BPLocations[1].Address.City}}
                  , {{dataService.BPLocations[1].Address.StateDesc}}
                </h4>
                <div class="propertysection-title">
                  <p class="title-text">
                    Building {{i+1}}
                  </p>
                </div>
                <div class="row-frame-h62">
                  <div class="field-main-prop">
                    <label class="field-label-readonly-prop">Coverage Basis</label>
                    <label class="field-value-prop">Replacement Cost</label>
                  </div>


                  <div class="field-box-w276"
                    *ngIf="(dataMappingService.businessFlags[BusinessAttribute.CONTRACTORS] && uiService.isInsuredSmallContractorSelectedVal!='Y')">
                    <label class="field-label-w276 ">Building Limit</label>
                    <input class="field-input-w276" formControlName="BuildingLimitInsurance"
                      placeholder="Amount in dollars" autocomplete="off" currencyNumericInput maxDigits="9"
                      id="property-details-building-limit" min="1" max="32"
                      (change)="validateBasicBuildingLimit($event.target.value, i, building)"
                      (keyup)="ShowFields($event.target.value, i, building)" />
                    <div *ngIf="
                        building.get('BuildingLimitInsurance').invalid &&
                          (building.get('BuildingLimitInsurance').dirty ||
                          building.get('BuildingLimitInsurance').touched) && 
                          building.get('BuildingLimitInsurance').errors.buildingLimit
                          " class="alert">
                      Building limit cannot be less than $1,000
                    </div>
                  </div>
                  <div class="field-box-w276" *ngIf="dataMappingService.businessFlags[BusinessAttribute.CONTRACTORS]">
                    <label class="field-label-w276 ">Business Personal Property Limit</label>
                    <input class="field-input-w276" formControlName="BusinssPersonalPropLmt"
                      placeholder="Amount in dollars" autocomplete="off" currencyNumericInput maxDigits="9"
                      id="property-details-business-personal-property-limit" min="1" max="32"
                      (change)="validateBasicBusinessPersonalPropertyLimit($event.target.value, i, 'PropertyDetailsLocationTwoForm', building)" />
                    <div *ngIf="
                          building.get('BusinssPersonalPropLmt').invalid &&
                          (building.get('BusinssPersonalPropLmt').dirty ||
                          building.get('BusinssPersonalPropLmt').touched)
                          " class="alert">
                      Business Personal Property must not exceed $5,000
                    </div>
                  </div>
                  <div class="field-box-w276"
                    *ngIf="!(dataMappingService.businessFlags[BusinessAttribute.CONTRACTORS])">
                    <label
                      [ngClass]="(building.get('buildinglimitMandatoryFlag').value)?'field-label-w276 required-field':'field-label-w276'">Building
                      Limit</label>
                    <input class="field-input-w276" formControlName="BuildingLimitInsurance"
                      placeholder="Amount in dollars" autocomplete="off" currencyNumericInput maxDigits="9"
                      id="property-details-building-limit" min="1" max="32"
                      (change)="validateBasicBuildingLimit($event.target.value, i, building)"
                      (keyup)="ShowFields($event.target.value, i, building)" />
                    <div *ngIf=" building.get('BuildingLimitInsurance').invalid &&
                    (building.get('BuildingLimitInsurance').dirty ||
                    building.get('BuildingLimitInsurance').touched) && 
                    building.get('BuildingLimitInsurance').errors.buildingLimit" class="alert">
                      Building limit cannot be less than $1,000
                    </div>
                  </div>
                  <div class="field-box-w276"
                    *ngIf="!(dataMappingService.businessFlags[BusinessAttribute.CONTRACTORS])">
                    <label
                      [ngClass]="(!building.get('buildinglimitMandatoryFlag').value)?'field-label-w276 required-field':'field-label-w276'">Business
                      Personal Property Limit</label>
                    <input class="field-input-w276" formControlName="BusinssPersonalPropLmt"
                      placeholder="Amount in dollars" autocomplete="off" currencyNumericInput maxDigits="9"
                      id="property-details-business-personal-property-limit" min="1" max="32"
                      (change)="validateBasicBusinessPersonalPropertyLimit($event.target.value, i, 'PropertyDetailsLocationTwoForm', building)" />
                    <div
                      *ngIf=" onIntrestChangeMandatoryBppLocationTwo &&  building.get('BusinssPersonalPropLmt').invalid || (building.get('BusinssPersonalPropLmt').invalid &&
                      (building.get('BusinssPersonalPropLmt').dirty ||
                      building.get('BusinssPersonalPropLmt').touched) && building.get('BusinssPersonalPropLmt').errors.businssPersonalPropLmt )"
                      class="alert">
                      Business Personal Property minimum $10,000
                    </div>
                  </div>
                  <div class="field-box-w276">
                    <label class="field-label-w276 required-field">Property Deductible</label>
                    <select class="field-input-w276" name="type" formControlName="OptionalPropertyDedu"
                      id="property-details-deductible">
                      <option class="dropdown-text" style="display:none"></option>
                      <option class="dropdown-text" [value]="deductible" *ngFor="let deductible of deductibleOptions">
                        {{ deductible }}
                      </option>
                    </select>
                  </div>

                </div>

              </div>

              <div class="row-frame-h62" style="height: 84px;padding-top: 32px">
                <div class="field-box-w276">
                  <label class="field-label-w276 required-field">Year of Construction</label>
                  <input class="field-input-w276" formControlName="YearBuilt" id="property-details-year-of-construction"
                    (change)="validateYearOfConstructionLimit($event.target.value, building)" (input)="yearBuiltValueCheck($event.target.value,'PropertyDetailsLocationOneForm',i)" minlength="0"
                    maxlength="4" onlyNumber required />
                  <div *ngIf="
                      building.get('YearBuilt').invalid &&
                        (building.get('YearBuilt').dirty ||
                        building.get('YearBuilt').touched)
                      " class="alert">
                    Required
                  </div>
                  <div *ngIf="building.get('yearofBuiltFailedFlag').value" class="alert">
                    Year of Built Should not be greater than Current year
                  </div>
                </div>

                <div class="field-box-w276">
                  <label class="field-label-w276 required-field">Construction Type</label>
                  <select class="field-input-w276" name="type" formControlName="ConstructionType"
                    id="property-details-construction-type">
                    <option class="dropdown-text" style="display:none"></option>
                    <option class="dropdown-text" [value]="construction"
                      *ngFor="let construction of dropDownService.constructionOptions">
                      {{ construction }}
                    </option>
                  </select>
                </div>

                <div class="field-box-w276">
                  <label class="field-label-w276 required-field">Distance from Fire Station</label>
                  <input class="field-input-w276" minlength="1" maxlength="100" onlyNumber [allowDecimal]="true"
                    formControlName="DistanceFromFireStation" (keyup)="_doPrefillOtherValues('two', building)"
                    id="property-details-distance-fire-station" required *ngIf="i == 0" />
                  <input class="field-input-w276" minlength="1" maxlength="100" onlyNumber [allowDecimal]="true"
                    formControlName="DistanceFromFireStation" id="property-details-distance-fire-station" required
                    *ngIf="i != 0" readonly />
                  <div class="field-box-w276" style="height: 18px">
                    <p class="hint">in miles</p>
                  </div>
                  <div *ngIf="
                  building.get('DistanceFromFireStation').invalid &&
                        (building.get('DistanceFromFireStation').dirty ||
                        building.get('DistanceFromFireStation').touched)
                      " class="alert">
                    Required
                  </div>
                </div>
                <div class="field-box-w276">
                  <label class="field-label-w276 required-field">Distance from Fire Hydrant</label>
                  <input class="field-input-w276" minlength="1" maxlength="100" onlyNumber
                    formControlName="DistToNearestHydrant" id="property-details-distance-fire-hydrant" required
                    *ngIf="i == 0" (keyup)="_doPrefillDFHValues('two', building)" />
                  <input class="field-input-w276" minlength="1" maxlength="100" onlyNumber
                    formControlName="DistToNearestHydrant" id="property-details-distance-fire-hydrant" required
                    *ngIf="i != 0" readonly />
                  <div class="field-box-w276" style="height: 18px">
                    <p class="hint">in feet</p>
                  </div>
                  <div *ngIf="
                  building.get('DistToNearestHydrant').invalid &&
                        (building.get('DistToNearestHydrant').dirty ||
                        building.get('DistToNearestHydrant').touched)
                      " class="alert">
                    Required
                  </div>
                </div>
              </div>
            </div>
            <div class="location-content-bottom">
              <div class="row-frame-h62">
                <div class="field-box-w276" style="width: 140px">
                  <label class="field-label-readonly-prop" style="width: 106px">Protection Class</label>
                  <label class="field-value-prop">{{ building.get("ppc").value }}</label>
                </div>
                <div class="field-box-w276" style="width: 676px" *ngIf="uiService.LocationTwoYearofConstruction[i].buildingCheck">
                  <label style="width: 676px">Has the building's roof, plumbing, water heater, heating and electrical,
                    been updated in the last 20 years?</label>
                  <div class="toggle-group-w288">
                    <div value="Y" class="toggle-button-left-w144" [ngClass]="
                    building.get('buildingroofselectedVal').value == 'Y'
                          ? 'selected-button'
                          : 'unselected-button'
                      " (click)="onbuildingroofclick(true, building)">
                      Yes
                    </div>
                    <div value="N" class="toggle-button-right-w144" [ngClass]="
                    building.get('buildingroofselectedVal').value == 'N'
                          ? 'selected-button'
                          : 'unselected-button'
                      " (click)="onbuildingroofclick(false, building)">
                      No
                    </div>
                  </div>
                </div>

                <div class="field-box-w276" style="width: 312px"
                  *ngIf="building.get('buildingroofselectedVal').value == 'Y'">
                  <label class="field-label-w376 required-field">What is the year of the oldest update?</label>
                  <input style="width: 312px" class="field-input-w276" onlyNumber formControlName="yearOfOldestUpdate"
                    id="property-year-of-oldest-update" minlength="0" maxlength="4" />
                </div>
              </div>
              <div class="row-frame-h62" style="height: 98px;">
                <div class="check-group-box">
                  <label class="field-label-w276">Local Alarm</label>

                  <div class="check-group-button">
                    <div class="check-group-field">
                      <input class="checkboxes" type="checkbox" id="localAlarmFireChecked"
                        formControlName="fireAlarmALocalAlarm" />
                      <label>Fire</label>
                    </div>
                    <div class="check-group-field">
                      <input class="checkboxes" type="checkbox" id="localAlarmBurglarChecked"
                        formControlName="CrmProtAlmALocalAlm" />
                      <label>Burglar</label>
                    </div>
                  </div>
                </div>
                <div class="check-group-box">
                  <label class="field-label-w276">Central Station Alarm</label>

                  <div class="check-group-button">
                    <div class="check-group-field">
                      <input class="checkboxes" type="checkbox" id="centralStationAlarmFireChecked"
                        formControlName="fireAlarmACenStnAlarm" />
                      <label>Fire</label>
                    </div>
                    <div class="check-group-field">
                      <input class="checkboxes" type="checkbox" id="centralStationAlarmBurglarChecked"
                        formControlName="CrmProtAlmACenStAlm" />
                      <label>Burglar</label>
                    </div>
                  </div>
                </div>
                <div class="check-group-box">
                  <label class="field-label-w276">Smoke Detectors</label>

                  <div class="check-group-button">
                    <div class="check-group-field">
                      <input class="checkboxes" type="checkbox" id="SmokeDetectorsBatteryChecked"
                        formControlName="fireAlarmABatopSmkDet" />
                      <label>Battery</label>
                    </div>
                    <div class="check-group-field">
                      <input class="checkboxes" type="checkbox" id="SmokeDetectorsHardwiredChecked"
                        formControlName="fireAlarmAHrdwrSmkDet" />
                      <label>Hardwired</label>
                    </div>
                  </div>
                </div>
                <div class="field-box-w276">
                  <label class="field-label-w276">Building is 100% Sprinklered
                    <div class="sprinkle_tooltip">
                      <img src="../../../assets/img/icon-info.png">
                      <span class="sprinkle_tooltiptext">
                        A sprinklered building has fully functional fire sprinkler coverage throughout the entire
                        building
                        and provides
                        both life safety and protection to the facility and its assets.
                      </span>
                    </div>
                  </label>
                  <div class="toggle-group-w288" style="width: 276px">
                    <div value="Y" class="toggle-button-left-w144" [ngClass]="
                        building.get('autoSprinkleredSystem').value == 'Y'
                          ? 'selected-button'
                          : 'unselected-button'
                      " (click)="sprinkleredSelectedCheck(true,building)">
                      Yes
                    </div>
                    <div value="N" class="toggle-button-right-w144" [ngClass]="
                    building.get('autoSprinkleredSystem').value == 'N'
                          ? 'selected-button'
                          : 'unselected-button'
                      " (click)="sprinkleredSelectedCheck(false,building)">
                      No
                    </div>
                  </div>
                </div>
              </div>

              <div class="row-frame-h62">
                <div class="field-box-w276">
                  <label class="field-label-w276 required-field">Damage To Premises Rented To You</label>
                  <select class="field-input-w276" formControlName="dmgPremRentYouLimit"
                    id="optional-coverages-damage-to-premises">
                    <option class="dropdown-text" value="">Select</option>
                    <option class="dropdown-text" [ngValue]="damageRented"
                      *ngFor="let damageRented of dropDownService.damageRentedLimitOptions">
                      {{ damageRented }}
                    </option>
                  </select>
                </div>
                <div class="field-box-w276" style="width: 507px;">
                  <label class="field-label-w276 required-field">Interest</label>
                  <select class="field-input-w276" style="width: 507px;" formControlName="Interest"
                    (change)="onInterestChange($event.target.value, i, 'PropertyDetailsLocationTwoForm')"
                    id="property-details-interest">
                    <option class="dropdown-text" value="">Select</option>
                    <option class="dropdown-text" *ngFor="let interest of processedInterestOptions"
                      [ngValue]="interest">
                      {{ interest }} </option>
                  </select>
                  <div *ngIf="
                      building.get('Interest').invalid &&
                      (building.get('Interest').dirty ||

                      building.get('Interest').touched)
                    " class="alert">
                    Required
                  </div>
                </div>

                <div class="field-box-w276" *ngIf="building.get('BuildingLimitInsurance').value">
                  <label [ngClass]="(buildingSquareFootageMandatoryFlagLocationTwo)?'field-label-w276 required-field':'field-label-w276'">Total Square Footage</label>
                  <input class="field-input-w276" minlength="1" maxlength="15" onlyNumber
                    formControlName="BuildingSquareFootage" id="property-details-total-square-footage" (keyup)="_doShowSqrFtValidation(building)" (input)="onSquareFootageChange(building)" />
                  <div *ngIf="building.get('totalSquareFootageFlag').value" class="alert">Square Footage Cannot Be 0</div>
                </div>

              </div>

              <div class="row-frame-h62" *ngIf="building.get('BuildingLimitInsurance').value">
                <div class="field-box-w276">
                  <label class="field-label-w276 required-field">Total Number of Units </label>
                  <input class="field-input-w276" minlength="1" maxlength="15" onlyNumber
                    formControlName="NumberOfUnits" id="property-details-total-number-of-units"
                    (keyup)="onTotalNumberOfUnitsChange(building)" />
                  <div *ngIf="building.get('NumberOfUnits').invalid &&
                    building.get('NumberOfUnits').errors.numberOfUnits" class="alert">
                    Total Number of Units must be greater than zero
                  </div>
                  <div *ngIf="!building.get('sumOfUnitsValidFlag').value" class="alert-numUnits">
                    <!-- Required -->
                    Total Number of Units must equal number of Owner-Occupied/Vacant/Leased Units
                  </div>

                </div>

                <div class="field-box-w276">
                  <label class="field-label-w276 required-field">Number of Owner-Occupied Units</label>
                  <input class="field-input-w276" minlength="1" maxlength="15" onlyNumber
                    (change)="calculateSumOfUnits(building)" formControlName="NumberOccupiedByOwners"
                    id="property-details-number-of-owner-occupied-units" />
                  <div *ngIf="
                      building.get('NumberOccupiedByOwners').invalid &&
                      (building.get('NumberOccupiedByOwners').dirty ||
                      building.get('NumberOccupiedByOwners').touched)
                    " class="alert">
                    Required
                  </div>
                </div>

                <div class="field-box-w276">
                  <label class="field-label-w276 required-field">Number of Vacant Units</label>
                  <input class="field-input-w276" minlength="1" maxlength="15" onlyNumber formControlName="NumberVacant"
                    id="property-details-number-vacant-units" (change)="calculateLeasedToOthers(building)" />
                  <div *ngIf="
                       building.get('NumberVacant').invalid &&
                       (building.get('NumberVacant').dirty ||
                       building.get('NumberVacant').touched)
                     " class="alert">
                    Required
                  </div>
                </div>

                <div class="field-box-w276">
                  <label class="field-label-w276 required-field">Number Leased to Others</label>
                  <input class="field-input-w276" minlength="1" maxlength="15" onlyNumber
                    formControlName="NumberLeasedToOthers" id="property-details-number-leased-to-others"
                    (change)="calculateSumOfUnits(building)" />
                  <div *ngIf="
                      building.get('NumberLeasedToOthers').invalid &&
                      (building.get('NumberLeasedToOthers').dirty ||
                      building.get('NumberLeasedToOthers').touched)
                    " class="alert">
                    Required
                  </div>
                </div>
              </div>

              <div class="field-box-w276"
                *ngIf="((dataMappingService.businessFlags[BusinessAttribute.PROCESSING_AND_SERVICE_RISKS])&&(uiService.processingPhotoService71899Flag) &&(dataMappingService.originalClassCodeObj.classcode2=='71899'))">
                <label class=" field-label-w276">Photography Endorsement</label>
                <div class="toggle-group-w276">
                  <div value="Y" class="toggle-button-left-w138"
                    [ngClass]="building.get('psPhotoServiceSelectedVal').value == 'Y' ? 'selected-button' : 'unselected-button'"
                    (click)="onPsPhotoServiceClick(true,building)"> YES </div>
                  <div value="N" class="toggle-button-right-w138"
                    [ngClass]="building.get('psPhotoServiceSelectedVal').value == 'N' ? 'selected-button' : 'unselected-button'"
                    (click)="onPsPhotoServiceClick(false,building)"> NO </div>
                </div>
              </div>
              <div class="field-box-w276"
                *ngIf="((dataMappingService.businessFlags[BusinessAttribute.OFFICE])&&(uiService.classcodeOffice64181Flag) && (dataMappingService.originalClassCodeObj.classcode2=='64181' || dataMappingService.originalClassCodeObj.classcode2=='64191') && (dataMappingService.originalClassCodeObj.description2!='Veterinarians Office - Condominium - Office - Lessors Risk Only' && dataMappingService.originalClassCodeObj.description2!='Veterinarians Office - Lessors Risk Only'))">
                <label class="field-label-w276">Veterinarian Endorsement</label>
                <div class="toggle-group-w276">
                  <div value="Y" class="toggle-button-left-w138"
                    [ngClass]="building.get('veterinarProfLiab').value == 'Y' ? 'selected-button' : 'unselected-button'"
                    (click)="onveterinarianEndorsementClick(true,building)"> YES </div>
                  <div value="N" class="toggle-button-right-w138"
                    [ngClass]="building.get('veterinarProfLiab').value == 'N' ? 'selected-button' : 'unselected-button'"
                    (click)="onveterinarianEndorsementClick(false,building)"> NO </div>
                </div>
              </div>
              <div class="field-box-w276" *ngIf="building.get('veterinarProfLiab').value == 'Y'">
                <label class="field-label-w276 "># of Veterinarians</label>
                <input class="field-input-w276" formControlName="numberOfVeterinarian" minlength="1" maxlength="20"
                  onlyNumber id="optional-coverages-no-full-time-employees" (input)="onVeterinarianChange(building)" />
                <div *ngIf="building.get('numberOfVeterinarianFailFlag').value" class="alert"> Number of Veterinarian
                  should be great than zero </div>
              </div>
              <!--Processing Service Starts -->
              <div class="field-box-w276"
                *ngIf="((dataMappingService.businessFlags[BusinessAttribute.PROCESSING_AND_SERVICE_RISKS]) && (uiService.processingProcessService7195271332Flag) && (dataMappingService.originalClassCodeObj.classcode2=='71952' || dataMappingService.originalClassCodeObj.classcode2=='71332'))">
                <label class="field-label-w276">Barbers/Beautician Professional Coverage</label>
                <div class="toggle-group-w276">
                  <div value="Y" class="toggle-button-left-w138"
                    [ngClass]="building.get('psBarberBeauticianSelectedVal').value == 'Y' ? 'selected-button' : 'unselected-button'"
                    (click)="onPsBarberBeauticianClick(true,building)">YES</div>
                  <div value="N" class="toggle-button-right-w138"
                    [ngClass]="building.get('psBarberBeauticianSelectedVal').value == 'N' ? 'selected-button' : 'unselected-button'"
                    (click)="onPsBarberBeauticianClick(false,building)">NO</div>
                </div>
              </div>
              <div class="field-box-w276"
                *ngIf="dataMappingService.businessFlags[BusinessAttribute.PROCESSING_AND_SERVICE_RISKS] && building.get('psBarberBeauticianSelectedVal').value == 'Y' && uiService.processingBarberService71332Flag && (dataMappingService.originalClassCodeObj.classcode2=='71332')">
                <label class="field-label-w276 required-field">Full Time Barber </label>
                <input class="field-input-w276" minlength="1" onlyNumber formControlName="noOfFullTimeBarbers"
                  required />
              </div>
              <div class="field-box-w276"
                *ngIf="dataMappingService.businessFlags[BusinessAttribute.PROCESSING_AND_SERVICE_RISKS] && building.get('psBarberBeauticianSelectedVal').value == 'Y' && uiService.processingBarberService71332Flag && (dataMappingService.originalClassCodeObj.classcode2=='71332')">
                <label class="field-label-w276 required-field">Part Time Barber </label>
                <input class="field-input-w276" minlength="1" onlyNumber formControlName="noOfPartTimeBarbers"
                  required />
              </div>
              <div class="field-box-w276"
                *ngIf="dataMappingService.businessFlags[BusinessAttribute.PROCESSING_AND_SERVICE_RISKS] && building.get('psBarberBeauticianSelectedVal').value == 'Y' && uiService.processingBeauticianService71952Flag && (dataMappingService.originalClassCodeObj.classcode2=='71952')">
                <label class="field-label-w276 required-field">Full Time Beauticians</label>
                <input class="field-input-w276" minlength="1" onlyNumber formControlName="noOfFlTmBuPrEnPf" required />
              </div>
              <div class="field-box-w276"
                *ngIf="dataMappingService.businessFlags[BusinessAttribute.PROCESSING_AND_SERVICE_RISKS] && building.get('psBarberBeauticianSelectedVal').value == 'Y' && uiService.processingBeauticianService71952Flag && (dataMappingService.originalClassCodeObj.classcode2=='71952')">
                <label class="field-label-w276 required-field">Part Time Beauticians</label>
                <input class="field-input-w276" minlength="1" onlyNumber formControlName="noOfPtTmBuPrEnPf" required />
              </div>
              <div class="field-box-w276"
                *ngIf="dataMappingService.businessFlags[BusinessAttribute.RESTAURANTS] || (dataMappingService.businessFlags[BusinessAttribute.CONVENIENCE] && uiService.convenience09331Flag && dataMappingService.originalClassCodeObj.classcode2=='09331')">
                <label class="field-label-w276 required-field">Gross Sales <div class="gross-sales-tooltip">
                    <img src="../../../assets/img/icon-info.png">
                    <span class="gross-sales-tooltiptext"> Gross sales includes any/all convenience items and restaurant
                      sales </span>
                  </div>
                </label>
                <input class="field-input-w276" placeholder="Amount in dollars" autocomplete="off" currencyInput min="1"
                  max="32" maxDigits="9" formControlName="AnualGrossSales" required />
              </div>
              <div class="field-box-w276" *ngIf="dataMappingService.businessFlags[BusinessAttribute.CONTRACTORS]">
                <label class="field-label-w276 required-field">Payroll <div class="payroll-tooltip">
                    <img src="../../../assets/img/icon-info.png">
                    <span class="payroll-tooltiptext"> Total payroll includes: <br>
                      <br> 1) {{policyStartPayroll}} for each Executive Officer, Individual Insured, or Co-partner <br>
                      2) Actual payroll for employee (full-time, part-time, temp) excluding clerical <br> 3) Any Leased
                      workers <br> 4) Any payments to uninsured subcontractors </span>
                  </div>
                </label>
                <input class="field-input-w276" placeholder="Amount in dollars" autocomplete="off" currencyInput min="1" max="32" maxDigits="9" formControlName="totalPayroll" id="liability-details-total-payroll" required (change)="liabilityPayrollValidadation(building)" />
                <div *ngIf="building.get('totalPayroll').invalid &&
                        (building.get('totalPayroll').dirty ||
                        building.get('totalPayroll').touched) &&
                        !building.get('isLiabilityPayrollNotValid').value" class="alert"> Required </div>
                <!-- Show appropriate info based upon if selected date is before or after the margin date -->
                <div *ngIf="building.get('isLiabilityPayrollNotValid').value && uiService.isDateAfter &&
                        building.get('totalPayroll').errors?.totalPayroll" class="alert"> Payroll must be equal to or greater than $40,000. </div>
                <div *ngIf="building.get('isLiabilityPayrollNotValid').value && !uiService.isDateAfter &&
                        building.get('totalPayroll').errors?.totalPayroll" class="alert"> Payroll must be equal to or greater than $35,700. </div>
              </div>
            </div>
             <!--Mortgage Code-->
             <div class="mortgage-container" formArrayName="mortgageInfo">
              <div class="mortgage-row" *ngFor="let x of LocationsTwoBuildings.controls[i].controls['mortgageInfo'].controls;index as mortgageIndex">
                <div class="mortgage-row-container" formGroupName="{{mortgageIndex}}">
                  <div style="width: 276px;">
                    <label class="mortgage-lbl required-field">Mortgage holder name</label>
                    <input class="mortgage-input" type="text" maxlength="80" required formControlName="mortgageHolderName" />
                  </div>
                  <div style="width: 200px;">
                    <label class="mortgage-lbl required-field">Street</label>
                    <input class="mortgage-input" type="text" maxlength="80" required formControlName="street"/>
                  </div>
                  <div>
                    <label class="mortgage-lbl required-field">City</label>
                    <input class="mortgage-input" type="text" maxlength="30" required formControlName="city"/>
                  </div>
                  <div>
                    <label class="mortgage-lbl required-field">State</label>
                    <input class="mortgage-input" type="text" maxlength="30" required formControlName="stateDesc"/>
                  </div>
                  <div>
                    <label class="mortgage-lbl required-field">Zip</label>
                    <input class="field-input-w276" minlength="0" maxlength="5" value="" onlyNumber required formControlName="zip" (change)=fetchByZipCode(x)/>                 </div>
                  <div class="delete-icon-container">
                    <i aria-label="Delete" class="material-icons" title="Remove mortgagee" (click)="onMortgageDelete(i,mortgageIndex,'PropertyDetailsLocationTwoForm')">delete</i>
                  </div>
                </div>
              </div>
            </div>
            <div class="addRemoveButtons">
              <div class="row-frame-h40" style="padding-top: 30px; width: 10px;">
                <button mat-stroked-button="mat-stroked-button" class="add-building-button" color="warn"
                  (click)="AddLocationTwoBuilding(i)" *ngIf="LocationsTwoBuildings?.controls.length < 3">
                  Add Building
                </button>
                <button style="width:270px" mat-stroked-button="mat-stroked-button" class="add-building-button" color="warn"
                (click)="onAddMortgageInfo('PropertyDetailsLocationTwoForm',i)" [disabled]="LocationsTwoBuildings.controls[i].controls['mortgageInfo'].length>1">
                  Add Mortgage Holder Information
                </button>
              </div>
              <div class="removebutton">
                <button mat-stroked-button="mat-stroked-button" *ngIf="LocationsTwoBuildings?.controls.length > 1"
                  class="remove-building-button" color="warn" (click)="RemoveLocationTwoBuilding(i)">
                  Remove Building
                  <mat-icon>delete_outline</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <form [formGroup]="PropertyDetailsLocationThreeForm" *ngIf="uiService.LocationThreeFlag">
      <div class="location-tabs-content" formArrayName="LocationThree">

        <div class="property-block" *ngIf="(dataMappingService.businessFlags[BusinessAttribute.APARTMENTS])
        || (dataMappingService.businessFlags[BusinessAttribute.RESTAURANTS])">
          <div class="property-box">
            <div>
              <div class="location-content">
                <h4 class="location-content-title">
                  Location 3 {{dataService.BPLocations[1].Address.Line1}} ,
                  {{dataService.BPLocations[2].Address.City}} ,
                  {{dataService.BPLocations[2].Address.StateDesc}}
                </h4>
              </div>
              <div class="field-box-w276" *ngIf="dataMappingService.businessFlags[BusinessAttribute.APARTMENTS]">
                <label class="field-label-w276">Apartment Buildings Endorsement</label>
                <div class="toggle-group-w276">
                  <div value="Y" class="toggle-button-left-w138" [ngClass]="
                  apartmentLocationThreeEndorsementSelectedVal == 'Y'
                      ? 'selected-button'
                      : 'unselected-button'
                      " (click)="locationOneApartmentBuildingEndorsementValChange(true, 2)">
                    Yes
                  </div>
                  <div value="N" class="toggle-button-right-w138" [ngClass]="
                  apartmentLocationThreeEndorsementSelectedVal == 'N'
                        ? 'selected-button'
                        : 'unselected-button'
                    " (click)="locationOneApartmentBuildingEndorsementValChange(false, 2)">
                    No
                  </div>
                </div>
              </div>
              <div class="field-box-w276" *ngIf="dataMappingService.businessFlags[BusinessAttribute.RESTAURANTS]">
                <label class="field-label-w276">Restaurant Endorsement
                  <div class="link-form-tooltip">
                    <img src="../../../assets/img/icon-info.png">
                    <span class="link-form-tooltiptext">
                      <a class="link-form-link" href='../../../assets/docs/BP 07 78 (07-13) Restaurants.pdf'
                        target="_blank" rel="noopener noreferrer">
                        Form BP 07 78
                      </a>
                    </span>
                  </div>
                </label>
                <div class="toggle-group-w276">
                  <div value="Y" class="toggle-button-left-w138" [ngClass]="
                  restaurantLocationThreeEndorsementSelectedVal == 'Y'
                          ? 'selected-button'
                          : 'unselected-button'
                    " (click)="restaurantEndorsementValChange(true, 2)">
                    Yes
                  </div>
                  <div value="N" class="toggle-button-right-w138" [ngClass]="
                  restaurantLocationThreeEndorsementSelectedVal == 'N'
                          ? 'selected-button'
                          : 'unselected-button'
                      " (click)="restaurantEndorsementValChange(false, 2)">
                    No
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="property-block" *ngFor="let building of LocationsThreeBuildings?.controls; let i = index">
          <div class="property-box" formGroupName="{{i}}">
            <div>
              <div class="location-content">
                <h4 class="location-content-title" *ngIf="dataService.BPLocations.length == 3">
                  Location 3 {{dataService.BPLocations[2].Address.Line1}} , {{dataService.BPLocations[2].Address.City}}
                  , {{dataService.BPLocations[2].Address.StateDesc}}
                </h4>
                <div class="propertysection-title">
                  <p class="title-text">
                    Building {{i+1}}
                  </p>
                </div>
                <div class="row-frame-h62">
                  <div class="field-main-prop">
                    <label class="field-label-readonly-prop">Coverage Basis</label>
                    <label class="field-value-prop">Replacement Cost</label>
                  </div>


                  <div class="field-box-w276"
                    *ngIf="(dataMappingService.businessFlags[BusinessAttribute.CONTRACTORS] && uiService.isInsuredSmallContractorSelectedVal!='Y')">
                    <label class="field-label-w276 ">Building Limit</label>
                    <input class="field-input-w276" formControlName="BuildingLimitInsurance"
                      placeholder="Amount in dollars" autocomplete="off" currencyNumericInput maxDigits="9"
                      id="property-details-building-limit" min="1" max="32"
                      (change)="validateBasicBuildingLimit($event.target.value, i, building)"
                      (keyup)="ShowFields($event.target.value, i, building)" />
                    <div *ngIf="
                        building.get('BuildingLimitInsurance').invalid &&
                          (building.get('BuildingLimitInsurance').dirty ||
                          building.get('BuildingLimitInsurance').touched) && 
                          building.get('BuildingLimitInsurance').errors.buildingLimit
                          " class="alert">
                      Building limit cannot be less than $1,000
                    </div>
                  </div>
                  <div class="field-box-w276" *ngIf="dataMappingService.businessFlags[BusinessAttribute.CONTRACTORS]">
                    <label class="field-label-w276 ">Business Personal Property Limit</label>
                    <input class="field-input-w276" formControlName="BusinssPersonalPropLmt"
                      placeholder="Amount in dollars" autocomplete="off" currencyNumericInput maxDigits="9"
                      id="property-details-business-personal-property-limit" min="1" max="32"
                      (change)="validateBasicBusinessPersonalPropertyLimit($event.target.value, i, 'PropertyDetailsLocationThreeForm', building)" />
                    <div *ngIf="
                          building.get('BusinssPersonalPropLmt').invalid &&
                          (building.get('BusinssPersonalPropLmt').dirty ||
                          building.get('BusinssPersonalPropLmt').touched)
                          " class="alert">
                      Business Personal Property must not exceed $5,000
                    </div>
                  </div>
                  <div class="field-box-w276"
                    *ngIf="!(dataMappingService.businessFlags[BusinessAttribute.CONTRACTORS])">
                    <label
                      [ngClass]="(building.get('buildinglimitMandatoryFlag').value)?'field-label-w276 required-field':'field-label-w276'">Building
                      Limit</label>
                    <input class="field-input-w276" formControlName="BuildingLimitInsurance"
                      placeholder="Amount in dollars" autocomplete="off" currencyNumericInput maxDigits="9"
                      id="property-details-building-limit" min="1" max="32"
                      (change)="validateBasicBuildingLimit($event.target.value, i, building)"
                      (keyup)="ShowFields($event.target.value, i, building)" />
                    <div *ngIf=" building.get('BuildingLimitInsurance').invalid &&
                          (building.get('BuildingLimitInsurance').dirty ||
                          building.get('BuildingLimitInsurance').touched) && 
                          building.get('BuildingLimitInsurance').errors.buildingLimit" class="alert">
                      Building limit cannot be less than $1,000
                    </div>
                  </div>
                  <div class="field-box-w276"
                    *ngIf="!(dataMappingService.businessFlags[BusinessAttribute.CONTRACTORS])">
                    <label
                      [ngClass]="(!building.get('buildinglimitMandatoryFlag').value)?'field-label-w276 required-field':'field-label-w276'">Business
                      Personal Property Limit</label>
                    <input class="field-input-w276" formControlName="BusinssPersonalPropLmt"
                      placeholder="Amount in dollars" autocomplete="off" currencyNumericInput maxDigits="9"
                      id="property-details-business-personal-property-limit" min="1" max="32"
                      (change)="validateBasicBusinessPersonalPropertyLimit($event.target.value, i, 'PropertyDetailsLocationThreeForm', building)" />
                    <div
                      *ngIf=" onIntrestChangeMandatoryBppLocationThree &&  building.get('BusinssPersonalPropLmt').invalid || (building.get('BusinssPersonalPropLmt').invalid &&
                      (building.get('BusinssPersonalPropLmt').dirty ||
                      building.get('BusinssPersonalPropLmt').touched) && building.get('BusinssPersonalPropLmt').errors.businssPersonalPropLmt )"
                      class="alert">
                      Business Personal Property minimum $10,000
                    </div>
                  </div>
                  <div class="field-box-w276">
                    <label class="field-label-w276 required-field">Property Deductible</label>
                    <select class="field-input-w276" name="type" formControlName="OptionalPropertyDedu"
                      id="property-details-deductible">
                      <option class="dropdown-text" style="display:none"></option>
                      <option class="dropdown-text" [value]="deductible" *ngFor="let deductible of deductibleOptions">
                        {{ deductible }}
                      </option>
                    </select>
                  </div>

                </div>

              </div>

              <div class="row-frame-h62" style="height: 84px;padding-top: 32px">
                <div class="field-box-w276">
                  <label class="field-label-w276 required-field">Year of Construction</label>
                  <input class="field-input-w276" formControlName="YearBuilt" id="property-details-year-of-construction"
                    (change)="validateYearOfConstructionLimit($event.target.value, building)" (input)="yearBuiltValueCheck($event.target.value,'PropertyDetailsLocationOneForm',i)" minlength="0"
                    maxlength="4" onlyNumber required />
                  <div *ngIf="
                      building.get('YearBuilt').invalid &&
                        (building.get('YearBuilt').dirty ||
                        building.get('YearBuilt').touched)
                      " class="alert">
                    Required
                  </div>
                  <div *ngIf="building.get('yearofBuiltFailedFlag').value" class="alert">
                    Year of Built Should not be greater than Current year
                  </div>
                </div>

                <div class="field-box-w276">
                  <label class="field-label-w276 required-field">Construction Type</label>
                  <select class="field-input-w276" name="type" formControlName="ConstructionType"
                    id="property-details-construction-type">
                    <option class="dropdown-text" style="display:none"></option>
                    <option class="dropdown-text" [value]="construction"
                      *ngFor="let construction of dropDownService.constructionOptions">
                      {{ construction }}
                    </option>
                  </select>
                </div>

                <div class="field-box-w276">
                  <label class="field-label-w276 required-field">Distance from Fire Station</label>
                  <input class="field-input-w276" minlength="1" maxlength="100" onlyNumber [allowDecimal]="true"
                    formControlName="DistanceFromFireStation" (keyup)="_doPrefillOtherValues('three', building)"
                    id="property-details-distance-fire-station" required *ngIf="i == 0" />
                  <input class="field-input-w276" minlength="1" maxlength="100" onlyNumber [allowDecimal]="true"
                    formControlName="DistanceFromFireStation" id="property-details-distance-fire-station" required
                    *ngIf="i != 0" readonly />
                  <div class="field-box-w276" style="height: 18px">
                    <p class="hint">in miles</p>
                  </div>
                  <div *ngIf="
                  building.get('DistanceFromFireStation').invalid &&
                        (building.get('DistanceFromFireStation').dirty ||
                        building.get('DistanceFromFireStation').touched)
                      " class="alert">
                    Required
                  </div>
                </div>
                <div class="field-box-w276">
                  <label class="field-label-w276 required-field">Distance from Fire Hydrant</label>
                  <input class="field-input-w276" minlength="1" maxlength="100" onlyNumber
                    formControlName="DistToNearestHydrant" id="property-details-distance-fire-hydrant" required
                    *ngIf="i == 0" (keyup)="_doPrefillDFHValues('three', building)" />
                  <input class="field-input-w276" minlength="1" maxlength="100" onlyNumber
                    formControlName="DistToNearestHydrant" id="property-details-distance-fire-hydrant" required
                    *ngIf="i != 0" readonly />
                  <div class="field-box-w276" style="height: 18px">
                    <p class="hint">in feet</p>
                  </div>
                  <div *ngIf="
                  building.get('DistToNearestHydrant').invalid &&
                        (building.get('DistToNearestHydrant').dirty ||
                        building.get('DistToNearestHydrant').touched)
                      " class="alert">
                    Required
                  </div>
                </div>
              </div>
            </div>
            <div class="location-content-bottom">
              <div class="row-frame-h62">
                <div class="field-box-w276" style="width: 140px">
                  <label class="field-label-readonly-prop" style="width: 106px">Protection Class</label>
                  <label class="field-value-prop">{{ building.get("ppc").value }}</label>
                </div>
                <div class="field-box-w276" style="width: 676px" *ngIf="uiService.LocationThreeYearofConstruction[i].buildingCheck">
                  <label style="width: 676px">Has the building's roof, plumbing, water heater, heating and electrical,
                    been updated in the last 20 years?</label>
                  <div class="toggle-group-w288">
                    <div value="Y" class="toggle-button-left-w144" [ngClass]="
                    building.get('buildingroofselectedVal').value == 'Y'
                          ? 'selected-button'
                          : 'unselected-button'
                      " (click)="onbuildingroofclick(true, building)">
                      Yes
                    </div>
                    <div value="N" class="toggle-button-right-w144" [ngClass]="
                    building.get('buildingroofselectedVal').value == 'N'
                          ? 'selected-button'
                          : 'unselected-button'
                      " (click)="onbuildingroofclick(false, building)">
                      No
                    </div>
                  </div>
                </div>

                <div class="field-box-w276" style="width: 312px"
                  *ngIf="building.get('buildingroofselectedVal').value == 'Y'">
                  <label class="field-label-w376 required-field">What is the year of the oldest update?</label>
                  <input style="width: 312px" class="field-input-w276" onlyNumber formControlName="yearOfOldestUpdate"
                    id="property-year-of-oldest-update" minlength="0" maxlength="4" />
                </div>
              </div>
              <div class="row-frame-h62" style="height: 98px;">
                <div class="check-group-box">
                  <label class="field-label-w276">Local Alarm</label>

                  <div class="check-group-button">
                    <div class="check-group-field">
                      <input class="checkboxes" type="checkbox" id="localAlarmFireChecked"
                        formControlName="fireAlarmALocalAlarm" />
                      <label>Fire</label>
                    </div>
                    <div class="check-group-field">
                      <input class="checkboxes" type="checkbox" id="localAlarmBurglarChecked"
                        formControlName="CrmProtAlmALocalAlm" />
                      <label>Burglar</label>
                    </div>
                  </div>
                </div>
                <div class="check-group-box">
                  <label class="field-label-w276">Central Station Alarm</label>

                  <div class="check-group-button">
                    <div class="check-group-field">
                      <input class="checkboxes" type="checkbox" id="centralStationAlarmFireChecked"
                        formControlName="fireAlarmACenStnAlarm" />
                      <label>Fire</label>
                    </div>
                    <div class="check-group-field">
                      <input class="checkboxes" type="checkbox" id="centralStationAlarmBurglarChecked"
                        formControlName="CrmProtAlmACenStAlm" />
                      <label>Burglar</label>
                    </div>
                  </div>
                </div>
                <div class="check-group-box">
                  <label class="field-label-w276">Smoke Detectors</label>

                  <div class="check-group-button">
                    <div class="check-group-field">
                      <input class="checkboxes" type="checkbox" id="SmokeDetectorsBatteryChecked"
                        formControlName="fireAlarmABatopSmkDet" />
                      <label>Battery</label>
                    </div>
                    <div class="check-group-field">
                      <input class="checkboxes" type="checkbox" id="SmokeDetectorsHardwiredChecked"
                        formControlName="fireAlarmAHrdwrSmkDet" />
                      <label>Hardwired</label>
                    </div>
                  </div>
                </div>
                <div class="field-box-w276">
                  <label class="field-label-w276">Building is 100% Sprinklered
                    <div class="sprinkle_tooltip">
                      <img src="../../../assets/img/icon-info.png">
                      <span class="sprinkle_tooltiptext">
                        A sprinklered building has fully functional fire sprinkler coverage throughout the entire
                        building
                        and provides
                        both life safety and protection to the facility and its assets.
                      </span>
                    </div>
                  </label>
                  <div class="toggle-group-w288" style="width: 276px">
                    <div value="Y" class="toggle-button-left-w144" [ngClass]="
                    building.get('autoSprinkleredSystem').value == 'Y'
                          ? 'selected-button'
                          : 'unselected-button'
                      " (click)="sprinkleredSelectedCheck(true,building)">
                      Yes
                    </div>
                    <div value="N" class="toggle-button-right-w144" [ngClass]="
                    building.get('autoSprinkleredSystem').value == 'N'
                          ? 'selected-button'
                          : 'unselected-button'
                      " (click)="sprinkleredSelectedCheck(false,building)">
                      No
                    </div>
                  </div>
                </div>
              </div>

              <div class="row-frame-h62">
                <div class="field-box-w276">
                  <label class="field-label-w276 required-field">Damage To Premises Rented To You</label>
                  <select class="field-input-w276" formControlName="dmgPremRentYouLimit"
                    id="optional-coverages-damage-to-premises">
                    <option class="dropdown-text" value="">Select</option>
                    <option class="dropdown-text" [ngValue]="damageRented"
                      *ngFor="let damageRented of dropDownService.damageRentedLimitOptions">
                      {{ damageRented }}
                    </option>
                  </select>
                </div>
                <div class="field-box-w276" style="width: 507px;">
                  <label class="field-label-w276 required-field">Interest</label>
                  <select class="field-input-w276" style="width: 507px;" formControlName="Interest"
                    (change)="onInterestChange($event.target.value, i, 'PropertyDetailsLocationThreeForm')"
                    id="property-details-interest">
                    <option class="dropdown-text" value="">Select</option>
                    <option class="dropdown-text" *ngFor="let interest of processedInterestOptions"
                      [ngValue]="interest">
                      {{ interest }} </option>
                  </select>
                  <div *ngIf="
                      building.get('Interest').invalid &&
                      (building.get('Interest').dirty ||

                      building.get('Interest').touched)
                    " class="alert">
                    Required
                  </div>
                </div>

                <div class="field-box-w276" *ngIf="building.get('BuildingLimitInsurance').value">
                  <label [ngClass]="(buildingSquareFootageMandatoryFlagLocationThree)?'field-label-w276 required-field':'field-label-w276'">Total Square Footage</label>
                  <input class="field-input-w276" minlength="1" maxlength="15" onlyNumber
                    formControlName="BuildingSquareFootage" id="property-details-total-square-footage" (keyup)="_doShowSqrFtValidation(building)" (input)="onSquareFootageChange(building)" />
                  <div *ngIf="building.get('totalSquareFootageFlag').value" class="alert">Square Footage Cannot Be 0</div>
                </div>
              </div>

              <div class="row-frame-h62" *ngIf="building.get('BuildingLimitInsurance').value">
                <div class="field-box-w276">
                  <label class="field-label-w276 required-field">Total Number of Units </label>
                  <input class="field-input-w276" minlength="1" maxlength="15" onlyNumber
                    formControlName="NumberOfUnits" id="property-details-total-number-of-units"
                    (keyup)="onTotalNumberOfUnitsChange(building)" />
                  <div *ngIf="building.get('NumberOfUnits').invalid &&
                  building.get('NumberOfUnits').errors.numberOfUnits" class="alert">
                    Total Number of Units must be greater than zero
                  </div>
                  <div *ngIf="!building.get('sumOfUnitsValidFlag').value" class="alert-numUnits">
                    <!-- Required -->
                    Total Number of Units must equal number of Owner-Occupied/Vacant/Leased Units
                  </div>

                </div>

                <div class="field-box-w276">
                  <label class="field-label-w276 required-field">Number of Owner-Occupied Units</label>
                  <input class="field-input-w276" minlength="1" maxlength="15" onlyNumber
                    (change)="calculateSumOfUnits(building)" formControlName="NumberOccupiedByOwners"
                    id="property-details-number-of-owner-occupied-units" />
                  <div *ngIf="
                      building.get('NumberOccupiedByOwners').invalid &&
                      (building.get('NumberOccupiedByOwners').dirty ||
                      building.get('NumberOccupiedByOwners').touched)
                    " class="alert">
                    Required
                  </div>
                </div>

                <div class="field-box-w276">
                  <label class="field-label-w276 required-field">Number of Vacant Units</label>
                  <input class="field-input-w276" minlength="1" maxlength="15" onlyNumber formControlName="NumberVacant"
                    id="property-details-number-vacant-units" (change)="calculateLeasedToOthers(building)" />
                  <div *ngIf="
                       building.get('NumberVacant').invalid &&
                       (building.get('NumberVacant').dirty ||
                       building.get('NumberVacant').touched)
                     " class="alert">
                    Required
                  </div>
                </div>

                <div class="field-box-w276">
                  <label class="field-label-w276 required-field">Number Leased to Others</label>
                  <input class="field-input-w276" minlength="1" maxlength="15" onlyNumber
                    formControlName="NumberLeasedToOthers" id="property-details-number-leased-to-others"
                    (change)="calculateSumOfUnits(building)" />
                  <div *ngIf="
                      building.get('NumberLeasedToOthers').invalid &&
                      (building.get('NumberLeasedToOthers').dirty ||
                      building.get('NumberLeasedToOthers').touched)
                    " class="alert">
                    Required
                  </div>
                </div>
              </div>

              <div class="field-box-w276"
                *ngIf="((dataMappingService.businessFlags[BusinessAttribute.PROCESSING_AND_SERVICE_RISKS])&&(uiService.processingPhotoService71899Flag) && (dataMappingService.originalClassCodeObj.classcode3=='71899'))">
                <label class=" field-label-w276">Photography Endorsement</label>
                <div class="toggle-group-w276">
                  <div value="Y" class="toggle-button-left-w138"
                    [ngClass]="building.get('psPhotoServiceSelectedVal').value == 'Y' ? 'selected-button' : 'unselected-button'"
                    (click)="onPsPhotoServiceClick(true, building)"> YES </div>
                  <div value="N" class="toggle-button-right-w138"
                    [ngClass]="building.get('psPhotoServiceSelectedVal').value == 'N' ? 'selected-button' : 'unselected-button'"
                    (click)="onPsPhotoServiceClick(false,building)"> NO </div>
                </div>
              </div>
              <div class="field-box-w276"
                *ngIf="((dataMappingService.businessFlags[BusinessAttribute.OFFICE])&&(uiService.classcodeOffice64181Flag) && (dataMappingService.originalClassCodeObj.classcode3=='64181' || dataMappingService.originalClassCodeObj.classcode3=='64191') && (dataMappingService.originalClassCodeObj.description3!='Veterinarians Office - Condominium - Office - Lessors Risk Only' && dataMappingService.originalClassCodeObj.description3!='Veterinarians Office - Lessors Risk Only'))">
                <label class="field-label-w276">Veterinarian Endorsement</label>
                <div class="toggle-group-w276">
                  <div value="Y" class="toggle-button-left-w138"
                    [ngClass]="building.get('veterinarProfLiab').value == 'Y' ? 'selected-button' : 'unselected-button'"
                    (click)="onveterinarianEndorsementClick(true, building)"> YES </div>
                  <div value="N" class="toggle-button-right-w138"
                    [ngClass]="building.get('veterinarProfLiab').value == 'N' ? 'selected-button' : 'unselected-button'"
                    (click)="onveterinarianEndorsementClick(false, building)"> NO </div>
                </div>
              </div>
              <div class="field-box-w276" *ngIf="building.get('veterinarProfLiab').value == 'Y'">
                <label class="field-label-w276 "># of Veterinarians</label>
                <input class="field-input-w276" formControlName="numberOfVeterinarian" minlength="1" maxlength="20"
                  onlyNumber id="optional-coverages-no-full-time-employees" (input)="onVeterinarianChange(building)" />
                <div *ngIf="building.get('numberOfVeterinarianFailFlag').value" class="alert"> Number of Veterinarian
                  should be great than zero </div>
              </div>
              <!--Processing Service Starts -->
              <div class="field-box-w276"
                *ngIf="((dataMappingService.businessFlags[BusinessAttribute.PROCESSING_AND_SERVICE_RISKS]) && (uiService.processingProcessService7195271332Flag) && (dataMappingService.originalClassCodeObj.classcode3=='71952' || dataMappingService.originalClassCodeObj.classcode3=='71332'))">
                <label class="field-label-w276">Barbers/Beautician Professional Coverage</label>
                <div class="toggle-group-w276">
                  <div value="Y" class="toggle-button-left-w138"
                    [ngClass]="building.get('psBarberBeauticianSelectedVal').value == 'Y' ? 'selected-button' : 'unselected-button'"
                    (click)="onPsBarberBeauticianClick(true, building)">YES</div>
                  <div value="N" class="toggle-button-right-w138"
                    [ngClass]="building.get('psBarberBeauticianSelectedVal').value == 'N' ? 'selected-button' : 'unselected-button'"
                    (click)="onPsBarberBeauticianClick(false, building)">NO</div>
                </div>
              </div>
              <div class="field-box-w276"
                *ngIf="dataMappingService.businessFlags[BusinessAttribute.PROCESSING_AND_SERVICE_RISKS] && building.get('psBarberBeauticianSelectedVal').value == 'Y' && uiService.processingBarberService71332Flag && (dataMappingService.originalClassCodeObj.classcode3=='71332')">
                <label class="field-label-w276 required-field">Full Time Barber </label>
                <input class="field-input-w276" minlength="1" onlyNumber formControlName="noOfFullTimeBarbers"
                  required />
              </div>
              <div class="field-box-w276"
                *ngIf="dataMappingService.businessFlags[BusinessAttribute.PROCESSING_AND_SERVICE_RISKS] && building.get('psBarberBeauticianSelectedVal').value == 'Y' && uiService.processingBarberService71332Flag && (dataMappingService.originalClassCodeObj.classcode3=='71332')">
                <label class="field-label-w276 required-field">Part Time Barber </label>
                <input class="field-input-w276" minlength="1" onlyNumber formControlName="noOfPartTimeBarbers"
                  required />
              </div>
              <div class="field-box-w276"
                *ngIf="dataMappingService.businessFlags[BusinessAttribute.PROCESSING_AND_SERVICE_RISKS] && building.get('psBarberBeauticianSelectedVal').value == 'Y' && uiService.processingBeauticianService71952Flag && (dataMappingService.originalClassCodeObj.classcode3=='71952')">
                <label class="field-label-w276 required-field">Full Time Beauticians</label>
                <input class="field-input-w276" minlength="1" onlyNumber formControlName="noOfFlTmBuPrEnPf" required />
              </div>
              <div class="field-box-w276"
                *ngIf="dataMappingService.businessFlags[BusinessAttribute.PROCESSING_AND_SERVICE_RISKS] && building.get('psBarberBeauticianSelectedVal').value == 'Y' && uiService.processingBeauticianService71952Flag && (dataMappingService.originalClassCodeObj.classcode3=='71952')">
                <label class="field-label-w276 required-field">Part Time Beauticians</label>
                <input class="field-input-w276" minlength="1" onlyNumber formControlName="noOfPtTmBuPrEnPf" required />
              </div>
              <div class="field-box-w276"
                *ngIf="dataMappingService.businessFlags[BusinessAttribute.RESTAURANTS] || (dataMappingService.businessFlags[BusinessAttribute.CONVENIENCE] && uiService.convenience09331Flag && dataMappingService.originalClassCodeObj.classcode3=='09331')">
                <label class="field-label-w276 required-field">Gross Sales <div class="gross-sales-tooltip">
                    <img src="../../../assets/img/icon-info.png">
                    <span class="gross-sales-tooltiptext"> Gross sales includes any/all convenience items and restaurant
                      sales </span>
                  </div>
                </label>
                <input class="field-input-w276" placeholder="Amount in dollars" autocomplete="off" currencyInput min="1"
                  max="32" maxDigits="9" formControlName="AnualGrossSales" required />
              </div>
              <div class="field-box-w276" *ngIf="dataMappingService.businessFlags[BusinessAttribute.CONTRACTORS]">
                <label class="field-label-w276 required-field">Payroll <div class="payroll-tooltip">
                    <img src="../../../assets/img/icon-info.png">
                    <span class="payroll-tooltiptext"> Total payroll includes: <br>
                      <br> 1) {{policyStartPayroll}} for each Executive Officer, Individual Insured, or Co-partner <br>
                      2) Actual payroll for employee (full-time, part-time, temp) excluding clerical <br> 3) Any Leased
                      workers <br> 4) Any payments to uninsured subcontractors </span>
                  </div>
                </label>
                <input class="field-input-w276" placeholder="Amount in dollars" autocomplete="off" currencyInput min="1" max="32" maxDigits="9" formControlName="totalPayroll" id="liability-details-total-payroll" required (change)="liabilityPayrollValidadation(building)" />
                <div *ngIf="building.get('totalPayroll').invalid &&
                        (building.get('totalPayroll').dirty ||
                        building.get('totalPayroll').touched) &&
                        !building.get('isLiabilityPayrollNotValid').value" class="alert"> Required </div>
                <!-- Show appropriate info based upon if selected date is before or after the margin date -->
                <div *ngIf="building.get('isLiabilityPayrollNotValid').value && uiService.isDateAfter &&
                        building.get('totalPayroll').errors?.totalPayroll" class="alert"> Payroll must be equal to or greater than $40,000. </div>
                <div *ngIf="building.get('isLiabilityPayrollNotValid').value && !uiService.isDateAfter &&
                        building.get('totalPayroll').errors?.totalPayroll" class="alert"> Payroll must be equal to or greater than $35,700. </div>
              </div>
            </div>
             <!--Mortgage Code-->
             <div class="mortgage-container" formArrayName="mortgageInfo">
              <div class="mortgage-row" *ngFor="let x of LocationsThreeBuildings.controls[i].controls['mortgageInfo'].controls;index as mortgageIndex">
                <div class="mortgage-row-container" formGroupName="{{mortgageIndex}}">
                  <div style="width: 276px;">
                    <label class="mortgage-lbl required-field">Mortgage holder name</label>
                    <input class="mortgage-input" type="text" maxlength="80" required formControlName="mortgageHolderName" />
                  </div>
                  <div style="width: 200px;">
                    <label class="mortgage-lbl required-field">Street</label>
                    <input class="mortgage-input" type="text" maxlength="80" required formControlName="street"/>
                  </div>
                  <div>
                    <label class="mortgage-lbl required-field">City</label>
                    <input class="mortgage-input" type="text" maxlength="30" required formControlName="city"/>
                  </div>
                  <div>
                    <label class="mortgage-lbl required-field">State</label>
                    <input class="mortgage-input" type="text" maxlength="30" required formControlName="stateDesc"/>
                  </div>
                  <div>
                    <label class="mortgage-lbl required-field">Zip</label>
                    <input class="field-input-w276" minlength="0" maxlength="5" value="" onlyNumber required formControlName="zip" (change)=fetchByZipCode(x)/>                 </div>
                  <div class="delete-icon-container">
                    <i aria-label="Delete" class="material-icons" title="Remove mortgagee" (click)="onMortgageDelete(i,mortgageIndex,'PropertyDetailsLocationThreeForm')">delete</i>
                  </div>
                </div>
              </div>
            </div>
            <div class="addRemoveButtons">
              <div class="row-frame-h40" style="padding-top: 30px; width: 10px;">
                <button mat-stroked-button="mat-stroked-button" class="add-building-button" color="warn"
                  (click)="AddLocationThreeBuilding(i)" *ngIf="LocationsThreeBuildings?.controls.length < 3">
                  Add Building
                </button>
                <button style="width:270px" mat-stroked-button="mat-stroked-button" class="add-building-button" color="warn"
                (click)="onAddMortgageInfo('PropertyDetailsLocationThreeForm',i)" [disabled]="LocationsThreeBuildings.controls[i].controls['mortgageInfo'].length>1">
                  Add Mortgage Holder Information
                </button>
              </div>
              <div class="removebutton">
                <button mat-stroked-button="mat-stroked-button" *ngIf="LocationsThreeBuildings?.controls.length > 1"
                  class="remove-building-button" color="warn" (click)="RemoveLocationThreeBuilding(i)">
                  Remove Building
                  <mat-icon>delete_outline</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="rectangle"></div>
</div>