import { Injectable } from "@angular/core";
import { Router } from '@angular/router';
import { cReconstrConstr, resAuRepMnufcProcOcup, recentPolCancld, otherLocations, SwimmingPoolExists, RecFacilities, PlayGroundExists, RiseToClaim, applicantSubcontract, CRecQues, foreignProducts, applicantLawsuit } from "@app/contractor.constant";
import { MarketplaceRequest, Question } from "@app/interfaces/marketplace-request.modal";
import { LoaderService } from "@app/loader.service";
import { DataMappingService } from "./data-mapping.service";
import { DataService } from "./data.service";
import { MarketplaceService } from "./marketplace.service";
import { UiService } from "./ui.service";
import jp from 'jsonpath';
import { BusinessAttribute } from "../enums/business-attributes.enum";
import { DeveloperService } from "./developer.service";
import { UWDataMappingService } from "./uw-data-mapping.service";
import { GoogleTagManagerService } from "@app/shared/google-tag-manager.service";

@Injectable({
    providedIn: 'root'
})
export class PrefillService {
    constructor(private router: Router, public dataMappingService: DataMappingService, private loaderService: LoaderService, public uiService: UiService, private marketplaceService: MarketplaceService, public developerService: DeveloperService, public uWDataMappingService: UWDataMappingService,public gtmService:GoogleTagManagerService) { }

    prefillData(interaction:string, pageName: string, dataService: DataService) {
        let payload: MarketplaceRequest;
        if(interaction == "2") {
            payload = this.prepareMarketplaceInteraction2Request(dataService.current_business_request);
        } else if (interaction == "3") {
            payload = this.prepareMarketplaceInteraction3Request(dataService.current_business_request);
        } else {
            payload = this.prepareMarketplaceInteraction4Request(dataService.current_business_request, dataService); 
        }

        this.loaderService.isLoadingFromStorage = true;
        this.loaderService.setLoaderText("Please wait...");
        this.marketplaceService.prefill(payload, interaction).subscribe(
            resp => {
                if(resp.apiOutput.statusCode == "200") {
                    if(resp.apiOutput.result.response && resp.apiOutput.result.response.eventResponses) {
                        this.loaderService.isLoadingFromStorage = false;
                        this.uiService.declineFlag = true;
                        this.uiService.declineReason = jp.query(resp, "$.apiOutput.result.response.eventResponses.eventResponse.attributes.attribute[?(@.name == 'DECLINE_MESSAGE')].value")[0];
                        this.gtmService.popupView(this.uiService.declineReason);
                        if(this.uiService.declineReason==undefined){
                            this.uiService.checkForDeclineInd(resp)
                        }
                        if(this.uiService.declineReason=='Coastal'){
                            this.uiService.declinationTitle='Thank you for this opportunity however the following location(s) do(es) not meet our eligibility guidelines for at least the following reasons'
                        }else {
                            this.uiService.declinedLocations=[];
                            this.uiService.declinationTitle='Thank you for this opportunity however your submission does not meet our eligibility guidelines for at least the following reason'
                        }
                     
                    } else {
                        if(interaction == "2") {
                            dataService.marketplace_prefill_response = resp;
    
                            this.uiService.habScore = jp.query(resp, "$.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[0].insurableObject.business.attributes.attribute[?(@.name == 'habScore')].value")[0];
                            this.uiService.dbScore = jp.query(resp, "$.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[0].insurableObject.business.attributes.attribute[?(@.name == 'dbScore')].value")[0];
                            dataService.current_business_request.quotePolicy.BusinessOwners.BPLocations.forEach((d,index)=>{
                                dataService.current_business_request.quotePolicy.BusinessOwners.BPLocations[index].Address['CCoastalZone']=dataService.marketplace_prefill_response.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[index].insurableObject.building.coastal_underwriting_guidelines;
                            })
                            this.loaderService.isLoadingFromStorage = false;
                            this.uiService.hasDataPrefilled = true;
                            this.uiService.agentFirstVisit=false;
                        } else if(interaction == "3") {
                           
                            this.uiService.SqftFlag = true;
                            this.uiService.SqftDeclineBuildings = [];
                            this.uiService.SqftAllBuildings = resp.apiOutput.result.message.response.eventResponses.eventResponse;
                            resp.apiOutput.result.message.response.eventResponses.eventResponse.forEach((element)=>{
                                if((jp.query(element, "$.attributes.attribute[?(@.name == 'DECLINE_IND')].value")[0])=='Y'){
                                    this.uiService.SqftDeclineFlag = true;
                                    this.uiService.SqftFlag = false;
                                    this.gtmService.popupView("square_footage");
                                }
                            })
                            if(this.uiService.SqftDeclineFlag){
                                resp.apiOutput.result.message.response.eventResponses.eventResponse.forEach((element, index)=>{
                                    if((jp.query(element, "$.attributes.attribute[?(@.name == 'DECLINE_IND')].value")[0])=='Y'){
                                        let obj:any={building:"",reason:"", location:""};
                                        obj.location =  resp.apiOutput.result.message.data.geographicLocationIdentifier;
                                        obj.buildingNo = index + 1
                                        obj.building=element;
                                        obj.reason=jp.query(element, "$.attributes.attribute[?(@.name == 'DECLINE_MESSAGE')].value")[0];
                                        this.uiService.SqftDeclineBuildings.push(obj);
                                        this.uiService.idToDeleteLocation=resp.apiOutput.result.message.data.geographicLocationIdentifier;
                                    }
                                   
                                })
                            }
                            else {
                                this.uiService.SqftFlag = true;
                                this.uiService.MoveNextTab.next('')
                            }
                            if(resp.apiOutput.result.message.data.geographicLocationIdentifier == '1' && resp.apiOutput.result.message.data.agreement != undefined){
                                this.dataMappingService.ppc= resp.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[0].insurableObject.building.risks.ppcCode;
                                this.dataMappingService.PPCCodeMapping(resp.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[0].insurableObject.building.risks.ppcCode,'1', dataService);
                            }
                           else if(resp.apiOutput.result.message.data.geographicLocationIdentifier== '2' && resp.apiOutput.result.message.data.agreement != undefined){
                                this.dataMappingService.ppc1= resp.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[0].insurableObject.building.risks.ppcCode;
                                this.dataMappingService.PPCCodeMapping(resp.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[0].insurableObject.building.risks.ppcCode,'2', dataService);
                            }
                            else if(resp.apiOutput.result.message.data.geographicLocationIdentifier== '3' && resp.apiOutput.result.message.data.agreement != undefined){
                                this.dataMappingService.ppc2= resp.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[0].insurableObject.building.risks.ppcCode;
                                this.dataMappingService.PPCCodeMapping(resp.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[0].insurableObject.building.risks.ppcCode,'3', dataService);
                            }
                            this.loaderService.isLoadingFromStorage = false;   
                        } else {
                            this.uiService.referralIndicator = resp.apiOutput.result.message.response.referrals.referralIndicator;
                            this.uiService.referralMessages = resp.apiOutput.result.message.response.referrals.referralMessages;
                            console.log(this.uiService.referralIndicator,"uiservice referal Indicator");
                            console.log(this.uiService.referralMessages,"uiservice referral messages")

                            // IRPM Data
                            this.dataMappingService.overallIRPMFactor = jp.query(resp, "$.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[0].insurableObject.business.IRPM.irpm[?(@.type == 'overallIRPM')].factor")[0];
                            this.dataMappingService.generalManagementIRPMJustification = jp.query(resp, "$.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[0].insurableObject.business.IRPM.irpm[?(@.type == 'General Management')].justification")[0];
                            this.dataMappingService.generalManagementIRPMFactor = jp.query(resp, "$.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[0].insurableObject.business.IRPM.irpm[?(@.type == 'General Management')].factor")[0];
                            this.dataMappingService.generalManagementIRPMFactor = this.dataMappingService.generalManagementIRPMFactor == "0" ? "" : this.dataMappingService.generalManagementIRPMFactor;
                            this.dataMappingService.buildingFeaturesIRPMJustification = jp.query(resp, "$.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[0].insurableObject.business.IRPM.irpm[?(@.type == 'Building Features')].justification")[0];
                            this.dataMappingService.buildingFeaturesIRPMFactor = jp.query(resp, "$.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[0].insurableObject.business.IRPM.irpm[?(@.type == 'Building Features')].factor")[0];
                            this.dataMappingService.buildingFeaturesIRPMFactor = this.dataMappingService.buildingFeaturesIRPMFactor == "0" ? "" : this.dataMappingService.buildingFeaturesIRPMFactor;
                            this.dataMappingService.locationIRPMJustification = jp.query(resp, "$.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[0].insurableObject.business.IRPM.irpm[?(@.type == 'Location')].justification")[0];
                            this.dataMappingService.locationIRPMFactor = jp.query(resp, "$.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[0].insurableObject.business.IRPM.irpm[?(@.type == 'Location')].factor")[0];
                            this.dataMappingService.locationIRPMFactor = this.dataMappingService.locationIRPMFactor == "0" ? "" : this.dataMappingService.locationIRPMFactor;
                            this.dataMappingService.protectionIRPMJustification = jp.query(resp, "$.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[0].insurableObject.business.IRPM.irpm[?(@.type == 'Protection/Loss Prevention')].justification")[0];
                            this.dataMappingService.protectionIRPMFactor = jp.query(resp, "$.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[0].insurableObject.business.IRPM.irpm[?(@.type == 'Protection/Loss Prevention')].factor")[0];
                            this.dataMappingService.protectionIRPMFactor = this.dataMappingService.protectionIRPMFactor == "0" ? "" : this.dataMappingService.protectionIRPMFactor;
                            this.dataMappingService.premisesAndEquipmentIRPMJustification = jp.query(resp, "$.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[0].insurableObject.business.IRPM.irpm[?(@.type == 'Premises and Equipment')].justification")[0];
                            this.dataMappingService.premisesAndEquipmentIRPMFactor = jp.query(resp, "$.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[0].insurableObject.business.IRPM.irpm[?(@.type == 'Premises and Equipment')].factor")[0];
                            this.dataMappingService.premisesAndEquipmentIRPMFactor = this.dataMappingService.premisesAndEquipmentIRPMFactor == "0" ? "" : this.dataMappingService.premisesAndEquipmentIRPMFactor;
                            this.dataMappingService.safetyManagementIRPMJustification = jp.query(resp, "$.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[0].insurableObject.business.IRPM.irpm[?(@.type == 'Safety Management')].justification")[0];
                            this.dataMappingService.safetyManagementIRPMFactor = jp.query(resp, "$.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[0].insurableObject.business.IRPM.irpm[?(@.type == 'Safety Management')].factor")[0];
                            this.dataMappingService.safetyManagementIRPMFactor = this.dataMappingService.safetyManagementIRPMFactor == "0" ? "" : this.dataMappingService.safetyManagementIRPMFactor;
                            this.dataMappingService.employeesIRPMJustification = jp.query(resp, "$.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[0].insurableObject.business.IRPM.irpm[?(@.type == 'Employees')].justification")[0];
                            this.dataMappingService.employeesIRPMFactor = jp.query(resp, "$.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[0].insurableObject.business.IRPM.irpm[?(@.type == 'Employees')].factor")[0];
                            this.dataMappingService.employeesIRPMFactor = this.dataMappingService.employeesIRPMFactor == "0" ? "" : this.dataMappingService.employeesIRPMFactor;

                            this.uWDataMappingService.mapDataBasedOnFlagName(this.dataMappingService, dataService);
                            this.dataMappingService.coverallMethod(this.developerService, dataService, this.uiService);
                        }
                    }
                    
                    if(interaction != "4" && !this.uiService.declineFlag && interaction != "3") {
                        this.router.navigate([`${pageName}`]);
                    }
                    if((!this.uiService.SqftDeclineFlag && interaction ==="3" && this.uiService.addedLocations.length < 2 && this.uiService.LocationOneTabVisited)  ||
                    (!this.uiService.SqftDeclineFlag && this.uiService.addedLocations.length < 3 && this.uiService.LocationOneTabVisited && this.uiService.LocationTwoTabVisited)  ||
                    (!this.uiService.SqftDeclineFlag && this.uiService.LocationOneTabVisited && this.uiService.LocationTwoTabVisited && this.uiService.LocationThreeTabVisited)){
                        this.router.navigate([`${pageName}`]);
                    }
                }
            },
            error => {
                console.log(error);
                this.loaderService.isLoadingFromStorage = false;
            }
        );
    }

    prepareMarketplaceInteraction2Request(data) {
        const smallContractor = ("Y" == data.quotePolicy.BusinessOwners.BPLocations[0].Address.cSmallContractor) ? "1" : "0";
      

        let mailingCity = data.quotePolicy.Insured.Address.City;
        mailingCity = mailingCity.toUpperCase();

        let lossAmt = 0;
        let lossesCount = 0;
        let lossHistory = data.quotePolicy.BusinessOwners.BPGeneralQuestionsMU.LosseHistory;
        if(lossHistory == "3 or more losses") {
            lossesCount = 3;
        } else if(lossHistory == "$250,000 or more in total paid and reserved losses") {
            lossAmt = 250000;
        } else if (data.quotePolicy.BusinessOwners.BPGeneralQuestionsMU.BPLossHistoryGeneralQuestions.length == 1) {
            lossesCount = 1;
            lossAmt = Number(data.quotePolicy.BusinessOwners.BPGeneralQuestionsMU.BPLossHistoryGeneralQuestions[0].lossPropReserv) + Number(data.quotePolicy.BusinessOwners.BPGeneralQuestionsMU.BPLossHistoryGeneralQuestions[0].lossLiabReserv);
        } else if(data.quotePolicy.BusinessOwners.BPGeneralQuestionsMU.BPLossHistoryGeneralQuestions.length == 2) {
            lossesCount = 2;
            lossAmt = Number(data.quotePolicy.BusinessOwners.BPGeneralQuestionsMU.BPLossHistoryGeneralQuestions[0].lossPropReserv) 
                        + Number(data.quotePolicy.BusinessOwners.BPGeneralQuestionsMU.BPLossHistoryGeneralQuestions[0].lossLiabReserv) 
                        + Number(data.quotePolicy.BusinessOwners.BPGeneralQuestionsMU.BPLossHistoryGeneralQuestions[1].lossPropReserv) 
                        + Number(data.quotePolicy.BusinessOwners.BPGeneralQuestionsMU.BPLossHistoryGeneralQuestions[1].lossLiabReserv);
        }
        let gLocations=[];
        let multiAttributes=[];
        this.uiService.addedLocations.forEach((d,index)=>{
            if(index==0){
                let t={classCode:this.dataMappingService.originalClassCodeObj.classcode,classDescription:this.dataMappingService.originalClassCodeObj.description}
                multiAttributes.push(t);
            }else if(index==1){
                let t={classCode:this.dataMappingService.originalClassCodeObj.classcode2,classDescription:this.dataMappingService.originalClassCodeObj.description2}
                multiAttributes.push(t);
            }else if(index==2){
                let t={classCode:this.dataMappingService.originalClassCodeObj.classcode3,classDescription:this.dataMappingService.originalClassCodeObj.description3}
                multiAttributes.push(t);
            }


        })
        console.log(multiAttributes,"Multi Attributes")
        this.uiService.addedLocations.forEach((d,index)=>{
            let city: string = data.quotePolicy.BusinessOwners.BPLocations[index].Address.City;
            city = city.toUpperCase();
            let x={
                geographicLocationIdentifier:index+1,
                locationAddress: {
                    line1Address: data.quotePolicy.BusinessOwners.BPLocations[index].Address.Line1,
                    cityName: city,
                    postalCode: data.quotePolicy.BusinessOwners.BPLocations[index].Address.ZipCode,
                    state: {
                        stateCode: data.quotePolicy.BusinessOwners.BPLocations[index].Address.StateCode,
                        stateName: data.quotePolicy.BusinessOwners.BPLocations[index].Address.StateDesc
                    }
                },
                attributes: {
                    attribute: [
                        {
                            name: "BOPClassCode",
                            value: multiAttributes[index].classCode
                        },
                        {
                            name: "BOPClassCodeDescription",
                            value: multiAttributes[index].classDescription
                        }
                    ]
                }
            }
            gLocations.push(x);
        })

        let payload: MarketplaceRequest = {
            message: {
                data: {
                    geographicLocations: {
                        geographicLocation: gLocations
                    },
                    agreement: {
                        policy: {
                            policy: {
                                insurableObjects: [
                                    {
                                        insurableObject: {
                                            business: {
                                                attributes: {
                                                    attribute: [
                                                        {
                                                            name: "smallContractorInd",
                                                            value: smallContractor
                                                        }
                                                   
                                                    ]
                                                },
                                                businessHeader: {
                                                    businessName: data.quotePolicy.Insured.BusinessName,
                                                    address: {
                                                        street: data.quotePolicy.Insured.Address.Line1,
                                                        city: mailingCity,
                                                        state: data.quotePolicy.Insured.Address.StateCode,
                                                        zip: data.quotePolicy.Insured.Address.ZipCode,
                                                        zipExtension: ""
                                                    }
                                                },
                                                businessFacts: {
                                                    salesRevenue: this.uiService.salesRevenue
                                                }
                                            }
                                        }
                                    }
                                ]
                            },
                            claim: {
                                attributes: {
                                    attribute: [
                                        {
                                            name: "numLosses",
                                            value: String(lossesCount)
                                        },
                                        {
                                            name: "lossAmt",
                                            value: String(lossAmt)
                                        }
                                    ]
                                }
                            }
                        }
                    }
                }
            }
        };

        return payload;
    }

    prepareMarketplaceInteraction3Request(data) {
        let city: string = data.quotePolicy.BusinessOwners.BPLocations[0].Address.City;
        city = city.toUpperCase(); 
        let Location = { 
            geographicLocationIdentifier: "",
            locationAddress: {
                line1Address:"",
                cityName: "",
                postalCode: "",
                state: {
                    stateCode: "",
                    stateName: ""
                }
            }
         }
        
        if(this.uiService.InteractionType == 1){
            Location.geographicLocationIdentifier = "1";
            Location.locationAddress.line1Address = data.quotePolicy.BusinessOwners.BPLocations[0].Address.Line1;
            Location.locationAddress.cityName = data.quotePolicy.BusinessOwners.BPLocations[0].Address.City.toUpperCase();
            Location.locationAddress.postalCode =data.quotePolicy.BusinessOwners.BPLocations[0].Address.ZipCode;
            Location.locationAddress.state.stateCode = data.quotePolicy.BusinessOwners.BPLocations[0].Address.StateCode;
            Location.locationAddress.state.stateName = data.quotePolicy.BusinessOwners.BPLocations[0].Address.stateName;
        }
        if(this.uiService.InteractionType == 2){
            Location.geographicLocationIdentifier = "2";
            Location.locationAddress.line1Address = data.quotePolicy.BusinessOwners.BPLocations[1].Address.Line1;
            Location.locationAddress.cityName = data.quotePolicy.BusinessOwners.BPLocations[1].Address.City.toUpperCase();
            Location.locationAddress.postalCode =data.quotePolicy.BusinessOwners.BPLocations[1].Address.ZipCode;
            Location.locationAddress.state.stateCode = data.quotePolicy.BusinessOwners.BPLocations[1].Address.StateCode;
            Location.locationAddress.state.stateName = data.quotePolicy.BusinessOwners.BPLocations[1].Address.stateName;
        }
        if(this.uiService.InteractionType == 3){
            Location.geographicLocationIdentifier = "3";
            Location.locationAddress.line1Address = data.quotePolicy.BusinessOwners.BPLocations[2].Address.Line1;
            Location.locationAddress.cityName = data.quotePolicy.BusinessOwners.BPLocations[2].Address.City.toUpperCase();
            Location.locationAddress.postalCode =data.quotePolicy.BusinessOwners.BPLocations[2].Address.ZipCode;
            Location.locationAddress.state.stateCode = data.quotePolicy.BusinessOwners.BPLocations[2].Address.StateCode;
            Location.locationAddress.state.stateName = data.quotePolicy.BusinessOwners.BPLocations[2].Address.stateName;
        }

        let insurableObjects= [];
        data.quotePolicy.BusinessOwners.BPBuilding.forEach((element, index)=>{
            if(this.uiService.InteractionType == element.locationId){
                insurableObjects.push({
                    insurableObject: {
                        building: {
                            number: (element.buildingId).toString(),
                            sqft: element.BuildingSquareFootage,
                            distanceToFireStation: element.DistanceFromFireStation,
                            distanceToFireHydrant: element.DistToNearestHydrant
                        }
                    }
                })
            }
        })

        let payload: MarketplaceRequest = {
            message: {
                data: {
                    geographicLocations: {
                        geographicLocation: [
                            Location
                        ]
                    },
                    agreement: {
                        policy: {
                            policy: {
                                insurableObjects: insurableObjects
                            }
                        }
                    }
                }
            }
        }

        return payload;
    }

    prepareMarketplaceInteraction4Request(data, dataService: DataService) {
        const smallContractor = ("Y" == data.quotePolicy.BusinessOwners.BPLocations[0].Address.cSmallContractor) ? "1" : "0";
      

        let lossAmt = 0;
        let lossesCount = 0;
        let lossHistory = data.quotePolicy.BusinessOwners.BPGeneralQuestionsMU.LosseHistory;
        if(lossHistory == "3 or more losses") {
            lossesCount = 3;
        } else if(lossHistory == "$250,000 or more in total paid and reserved losses") {
            lossAmt = 250000;
        } else if (data.quotePolicy.BusinessOwners.BPGeneralQuestionsMU.BPLossHistoryGeneralQuestions.length == 1) {
            lossesCount = 1;
            lossAmt = Number(data.quotePolicy.BusinessOwners.BPGeneralQuestionsMU.BPLossHistoryGeneralQuestions[0].lossPropReserv) + Number(data.quotePolicy.BusinessOwners.BPGeneralQuestionsMU.BPLossHistoryGeneralQuestions[0].lossLiabReserv);
        } else if(data.quotePolicy.BusinessOwners.BPGeneralQuestionsMU.BPLossHistoryGeneralQuestions.length == 2) {
            lossesCount = 2;
            lossAmt = Number(data.quotePolicy.BusinessOwners.BPGeneralQuestionsMU.BPLossHistoryGeneralQuestions[0].lossPropReserv) 
                        + Number(data.quotePolicy.BusinessOwners.BPGeneralQuestionsMU.BPLossHistoryGeneralQuestions[0].lossLiabReserv) 
                        + Number(data.quotePolicy.BusinessOwners.BPGeneralQuestionsMU.BPLossHistoryGeneralQuestions[1].lossPropReserv) 
                        + Number(data.quotePolicy.BusinessOwners.BPGeneralQuestionsMU.BPLossHistoryGeneralQuestions[1].lossLiabReserv);
        }
        
        let blanketLimit = "0";
        if(this.dataMappingService.businessFlags[BusinessAttribute.CONTRACTORS]) {
            blanketLimit = data.quotePolicy.BusinessOwners.contractorsEndorsement=='Y'?data.quotePolicy.BusinessOwners.BPContractorsEndorsement.blanketLimit:blanketLimit;
        }
        let liquorLiabilityInd = "0";
        if(this.dataMappingService.businessFlags[BusinessAttribute.MERCANTILE_RISKS] || this.dataMappingService.businessFlags[BusinessAttribute.RESTAURANTS] || this.dataMappingService.businessFlags[BusinessAttribute.CONVENIENCE]) {
            liquorLiabilityInd = this.uiService.liquorLiabilityCoverageSelectedFlag == "Y" ? "1" : "0";
        }

        let natureOfBusiness: string = data.quotePolicy.BusinessOwners.BPBuilding[0].PropertyType;
        natureOfBusiness = (natureOfBusiness.length > 50) ? natureOfBusiness.substring(0, 50) : natureOfBusiness;
        
       
       

        let mailingCity = data.quotePolicy.Insured.Address.City;
        mailingCity = mailingCity.toUpperCase();

        let sales = "";
      
        let gLocations=[];
        let LocationOneBuildings=[];
        let LocationTwoBuildings=[];
        let LocationThreeBuildings=[];
        let iObjects=[];
        let tPayroll=0;
        
        dataService.current_business_request.quotePolicy.BusinessOwners.BPBuilding.forEach((d)=>{
            if(this.dataMappingService.businessFlags[BusinessAttribute.CONTRACTORS]){
                tPayroll=tPayroll+Number(this.dataMappingService.removeCommasAndDollar(d.BPClassifications.liabilityExposure));
            }
            if(d.locationId==1){
              LocationOneBuildings.push(d);
            }else if(d.locationId==2){
              LocationTwoBuildings.push(d);
            }else if(d.locationId==3){
              LocationThreeBuildings.push(d);
            }
           })
        this.uiService.addedLocations.forEach((d,index)=>{
            let city: string = data.quotePolicy.BusinessOwners.BPLocations[index].Address.City;
            city = city.toUpperCase();
            let requiredIndex=index;
            if(index==1){
                requiredIndex=LocationOneBuildings.length;
            }else if(index==2){
                requiredIndex=LocationOneBuildings.length+LocationTwoBuildings.length;
            }
            let obj=  {
                geographicLocationIdentifier: index+1,
                locationAddress: {
                    line1Address: data.quotePolicy.BusinessOwners.BPLocations[index].Address.Line1,
                    cityName: city,
                    postalCode: data.quotePolicy.BusinessOwners.BPLocations[index].Address.ZipCode,
                    state: {
                        stateCode: data.quotePolicy.BusinessOwners.BPLocations[index].Address.StateCode,
                        stateName: data.quotePolicy.BusinessOwners.BPLocations[index].Address.StateDesc
                    }
                },
                attributes:{
                    attribute:[
                        {
                            name: "BOPClassCode",
                            value: data.quotePolicy.BusinessOwners.BPBuilding[requiredIndex].BPClassifications.ClassCode
                        },
                        {
                            name: "BOPClassCodeDescription",
                            value: data.quotePolicy.BusinessOwners.BPBuilding[requiredIndex].BPClassifications.classificationDesc
                        }
                    ]
                }
            }
            gLocations.push(obj);
        })
        console.log(gLocations,"Interaction-4 gLocations");
     
        LocationOneBuildings.forEach((d,index)=>{
            let buildingLimit = d.BPBuildingCoverage!=null?d.BPBuildingCoverage.BuildingLimitInsurance:"";
            buildingLimit = (buildingLimit == "") ? "0" : buildingLimit;
            let bppLimit = d.BPClassifications.BusinssPersonalPropLmt;
            bppLimit = (bppLimit == "") ? "0" : bppLimit;
            let x=  {
                insurableObject: {
                    business: {
                        attributes: {
                            attribute: [
                                {
                                    name: "smallContractorInd",
                                    value: smallContractor
                                },
                                {
                                    name: "habScore",
                                    value: this.uiService.habScore
                                },
                                {
                                    name: "dbScore",
                                    value: this.uiService.dbScore
                                },
                                {
                                    name: "nfcraScore",
                                    value: this.dataMappingService.nfcraScore
                                },
                                {
                                    name: "AvC",
                                    value: ""
                                },
                                {
                                    name: "BLANKET_TOOLS_EQP_LIMIT",
                                    value: blanketLimit
                                },
                                {
                                    name: "payroll",
                                    value: tPayroll
                                }
                            ]
                        },
                        businessHeader: {
                            bin: "",
                            businessName: data.quotePolicy.Insured.BusinessName,
                            address: {
                                street: data.quotePolicy.Insured.Address.Line1,
                                city: mailingCity,
                                state: data.quotePolicy.Insured.Address.StateCode,
                                zip: data.quotePolicy.Insured.Address.ZipCode,
                                zipExtension: ""
                            }
                        },
                        businessFacts: {
                            yearsInBusiness: Number(data.quotePolicy.Insured.yearsOfOperation),
                            businessType: natureOfBusiness,
                            salesRevenue: this.uiService.salesRevenue
                        },
                        sicCodes: {
                            code: this.uiService.sicCode
                        },
                        naicsCodes: {
                            code: this.uiService.naicsCode
                        }
                    },
                    building: {
                        geographicLocationIdentifier:1,
                        number:index+1,
                        sqft: d.BuildingSquareFootage,
                        constructionType: d.ConstructionType,
                        numUnits: d.NumberOfUnits,
                        distanceToFireStation: d.DistanceFromFireStation,
                        distanceToFireHydrant: d.DistToNearestHydrant,
                        landUse: "",
                        attributes: {
                            attribute: [
                                {
                                    name: "buildingLimit",
                                    value: buildingLimit
                                },
                                {
                                    name: "bppLimit",
                                    value: bppLimit
                                },
                                {
                                    name: "costal_underwriting_guidelines",
                                    value: data.quotePolicy.BusinessOwners.BPLocations[0].Address.CCoastalZone
                                },
                                {
                                    name: "sprinkleredInd",
                                    value: ("Y" === d.autoSprinkleredSystem) ? "1" : "0"
                                },
                                {
                                    name: "numVacantUnits",
                                    value: d.NumberVacant
                                },
                                {
                                    name: "ppc",
                                    value: d.PublicProtFireClas
                                },
                                {
                                    name: "Sales",
                                    value: this.dataMappingService.businessFlags[BusinessAttribute.RESTAURANTS] || this.dataMappingService.businessFlags[BusinessAttribute.CONVENIENCE]?d.BPClassifications.liabilityExposure:sales
                                },
                                {
                                    name: "UW_BLDG_UPDATES",
                                    value: this.uiService.LocationOneYearofConstruction[index].buildingCheck ? this.dataMappingService.boolToYN(d.BPClassifications.isBuildingRoofValueSelected) : ""
                                }
                            ]
                        }
                    },
                    liquor: {
                        attributes: {
                            attribute: [
                                {
                                    name: "liqLiabilityInd",
                                    value: liquorLiabilityInd
                                }
                            ]
                        }
                    }
                }
            }
            iObjects.push(x);
        })
        LocationTwoBuildings.forEach((d,index)=>{
            let buildingLimit = d.BPBuildingCoverage!=null?d.BPBuildingCoverage.BuildingLimitInsurance:"";
            buildingLimit = (buildingLimit == "") ? "0" : buildingLimit;
            let bppLimit = d.BPClassifications.BusinssPersonalPropLmt;
            bppLimit = (bppLimit == "") ? "0" : bppLimit;
            let x=  {
                insurableObject: {
                    business: {
                        attributes: {
                            attribute: [
                                {
                                    name: "smallContractorInd",
                                    value: smallContractor
                                },
                                {
                                    name: "habScore",
                                    value: this.uiService.habScore
                                },
                                {
                                    name: "dbScore",
                                    value: this.uiService.dbScore
                                },
                                {
                                    name: "nfcraScore",
                                    value: this.dataMappingService.nfcraScore
                                },
                                {
                                    name: "AvC",
                                    value: ""
                                },
                                {
                                    name: "BLANKET_TOOLS_EQP_LIMIT",
                                    value: blanketLimit
                                },
                                {
                                    name: "payroll",
                                    value: tPayroll
                                }
                            ]
                        },
                        businessHeader: {
                            bin: "",
                            businessName: data.quotePolicy.Insured.BusinessName,
                            address: {
                                street: data.quotePolicy.Insured.Address.Line1,
                                city: mailingCity,
                                state: data.quotePolicy.Insured.Address.StateCode,
                                zip: data.quotePolicy.Insured.Address.ZipCode,
                                zipExtension: ""
                            }
                        },
                        businessFacts: {
                            yearsInBusiness: Number(data.quotePolicy.Insured.yearsOfOperation),
                            businessType: natureOfBusiness,
                            salesRevenue: this.uiService.salesRevenue
                        },
                        sicCodes: {
                            code: this.uiService.sicCode
                        },
                        naicsCodes: {
                            code: this.uiService.naicsCode
                        }
                    },
                    building: {
                        geographicLocationIdentifier:2,
                        number:index+1,
                        sqft: d.BuildingSquareFootage,
                        constructionType: d.ConstructionType,
                        numUnits: d.NumberOfUnits,
                        distanceToFireStation: d.DistanceFromFireStation,
                        distanceToFireHydrant: d.DistToNearestHydrant,
                        landUse: "",
                        attributes: {
                            attribute: [
                                {
                                    name: "buildingLimit",
                                    value: buildingLimit
                                },
                                {
                                    name: "bppLimit",
                                    value: bppLimit
                                },
                                {
                                    name: "costal_underwriting_guidelines",
                                    value: data.quotePolicy.BusinessOwners.BPLocations[0].Address.CCoastalZone
                                },
                                {
                                    name: "sprinkleredInd",
                                    value: ("Y" === d.autoSprinkleredSystem) ? "1" : "0"
                                },
                                {
                                    name: "numVacantUnits",
                                    value: d.NumberVacant
                                },
                                {
                                    name: "ppc",
                                    value: d.PublicProtFireClas
                                },
                                {
                                    name: "Sales",
                                    value: this.dataMappingService.businessFlags[BusinessAttribute.RESTAURANTS] || this.dataMappingService.businessFlags[BusinessAttribute.CONVENIENCE]?d.BPClassifications.liabilityExposure:sales
                                },
                                {
                                    name: "UW_BLDG_UPDATES",
                                    value: this.uiService.LocationTwoYearofConstruction[index].buildingCheck ? this.dataMappingService.boolToYN(d.BPClassifications.isBuildingRoofValueSelected) : ""
                                }
                            ]
                        }
                    },
                    liquor: {
                        attributes: {
                            attribute: [
                                {
                                    name: "liqLiabilityInd",
                                    value: liquorLiabilityInd
                                }
                            ]
                        }
                    }
                }
            }
            iObjects.push(x);
        })
        LocationThreeBuildings.forEach((d,index)=>{
            let buildingLimit = d.BPBuildingCoverage!=null?d.BPBuildingCoverage.BuildingLimitInsurance:"";
            buildingLimit = (buildingLimit == "") ? "0" : buildingLimit;
            let bppLimit = d.BPClassifications.BusinssPersonalPropLmt;
            bppLimit = (bppLimit == "") ? "0" : bppLimit;
            let x=  {
                insurableObject: {
                    business: {
                        attributes: {
                            attribute: [
                                {
                                    name: "smallContractorInd",
                                    value: smallContractor
                                },
                                {
                                    name: "habScore",
                                    value: this.uiService.habScore
                                },
                                {
                                    name: "dbScore",
                                    value: this.uiService.dbScore
                                },
                                {
                                    name: "nfcraScore",
                                    value: this.dataMappingService.nfcraScore
                                },
                                {
                                    name: "AvC",
                                    value: ""
                                },
                                {
                                    name: "BLANKET_TOOLS_EQP_LIMIT",
                                    value: blanketLimit
                                },
                                {
                                    name: "payroll",
                                    value: tPayroll
                                }
                            ]
                        },
                        businessHeader: {
                            bin: "",
                            businessName: data.quotePolicy.Insured.BusinessName,
                            address: {
                                street: data.quotePolicy.Insured.Address.Line1,
                                city: mailingCity,
                                state: data.quotePolicy.Insured.Address.StateCode,
                                zip: data.quotePolicy.Insured.Address.ZipCode,
                                zipExtension: ""
                            }
                        },
                        businessFacts: {
                            yearsInBusiness: Number(data.quotePolicy.Insured.yearsOfOperation),
                            businessType: natureOfBusiness,
                            salesRevenue: this.uiService.salesRevenue
                        },
                        sicCodes: {
                            code: this.uiService.sicCode
                        },
                        naicsCodes: {
                            code: this.uiService.naicsCode
                        }
                    },
                    building: {
                        geographicLocationIdentifier:3,
                        number:index+1,
                        sqft: d.BuildingSquareFootage,
                        constructionType: d.ConstructionType,
                        numUnits: d.NumberOfUnits,
                        distanceToFireStation: d.DistanceFromFireStation,
                        distanceToFireHydrant: d.DistToNearestHydrant,
                        landUse: "",
                        attributes: {
                            attribute: [
                                {
                                    name: "buildingLimit",
                                    value: buildingLimit
                                },
                                {
                                    name: "bppLimit",
                                    value: bppLimit
                                },
                                {
                                    name: "costal_underwriting_guidelines",
                                    value: data.quotePolicy.BusinessOwners.BPLocations[0].Address.CCoastalZone
                                },
                                {
                                    name: "sprinkleredInd",
                                    value: ("Y" === d.autoSprinkleredSystem) ? "1" : "0"
                                },
                                {
                                    name: "numVacantUnits",
                                    value: d.NumberVacant
                                },
                                {
                                    name: "ppc",
                                    value: d.PublicProtFireClas
                                },
                                {
                                    name: "Sales",
                                    value: this.dataMappingService.businessFlags[BusinessAttribute.RESTAURANTS] || this.dataMappingService.businessFlags[BusinessAttribute.CONVENIENCE]?d.BPClassifications.liabilityExposure:sales
                                },
                                {
                                    name: "UW_BLDG_UPDATES",
                                    value: this.uiService.LocationThreeYearofConstruction[index].buildingCheck ? this.dataMappingService.boolToYN(d.BPClassifications.isBuildingRoofValueSelected) : ""
                                }
                            ]
                        }
                    },
                    liquor: {
                        attributes: {
                            attribute: [
                                {
                                    name: "liqLiabilityInd",
                                    value: liquorLiabilityInd
                                }
                            ]
                        }
                    }
                }
            }
            iObjects.push(x);
        })
        console.log(iObjects,"Insurable objects I4")
        this.uiService.checkForUniqueId();
        let payload: MarketplaceRequest = {
            message: {
                data: {
                    geographicLocations: {
                        geographicLocation: gLocations
                    },
                    agreement: {
                        policy: {
                            policy: {
                                CoverallQuoteIdentifier:this.uiService.uniqueId,
                                effectiveDate: data.quotePolicy.effectiveDate,
                                Questions: {
                                    Question: this.mapUWQuestions(data)
                                },
                                insurableObjects: iObjects
                            },
                            claim: {
                                attributes: {
                                    attribute: [
                                        {
                                            name: "numLosses",
                                            value: String(lossesCount)
                                        },
                                        {
                                            name: "lossAmt",
                                            value: String(lossAmt)
                                        }
                                    ]
                                }
                            }
                        }
                    }
                }
            }
        }

        return payload;
    }

    mapUWQuestions(data): Array<Question> {
        let questionAnswers: Array<Question> = [];
        questionAnswers.push({
            Question: "UW_UNDER_CONSTRUCTION",
            answer: this.dataMappingService.currentForm.get(cReconstrConstr).value
        });
        questionAnswers.push({
            Question: "UW_SPECIFIC_OCCUPANT",
            answer: this.dataMappingService.currentForm.get(resAuRepMnufcProcOcup).value
        });
        questionAnswers.push({
            Question: "UW_PRIOR_POL_CANCEL",
            answer: this.dataMappingService.currentForm.get(recentPolCancld).value
        });
        questionAnswers.push({
            Question: "UW_ADDL_LOCATIONS",
            answer: this.dataMappingService.currentForm.get(otherLocations).value
        });
      

        if(this.dataMappingService.businessFlags[BusinessAttribute.CONTRACTORS]) {
            questionAnswers.push({
                Question: "UW_SUBCONTRACTOR",
                answer: data.quotePolicy.BusinessOwners.BPGeneralQuestionsMU.applicantSubcontract
            });
            questionAnswers.push({
                Question: "UW_CONSTRUCTION_DEFECT",
                answer: this.dataMappingService.currentForm.get(applicantLawsuit).value
            });
        } else if(this.dataMappingService.businessFlags[BusinessAttribute.APARTMENTS]) {
            questionAnswers.push({
                Question: "UW_SWIMMING_POOL",
                answer: this.dataMappingService.currentForm.get(SwimmingPoolExists).value
            });
            questionAnswers.push({
                Question: "UW_REC_FACILITY",
                answer: this.dataMappingService.currentForm.get(CRecQues).value
            });
            questionAnswers.push({
                Question: "UW_PLAYGROUND",
                answer: this.dataMappingService.currentForm.get(PlayGroundExists).value
            });
            questionAnswers.push({
                Question: "UW_CLAIM_KNOWLEDGE",
                answer: this.dataMappingService.currentForm.get(RiseToClaim).value
            });
        } else if(this.dataMappingService.businessFlags[BusinessAttribute.MERCANTILE_RISKS] || this.dataMappingService.businessFlags[BusinessAttribute.WHOLESALE_RISKS]) {
            questionAnswers.push({
                Question: "UW_FOREIGN_IMPORTS",
                answer: this.dataMappingService.currentForm.get(foreignProducts).value
            });
        } else if(this.dataMappingService.businessFlags[BusinessAttribute.OFFICE]) {
            questionAnswers.push({
                Question: "UW_CLAIM_KNOWLEDGE",
                answer: this.dataMappingService.currentForm.get(RiseToClaim).value
            });
        }

        return questionAnswers;
    }
}
