import { Component, OnInit } from "@angular/core";
import { AuthService } from "@app/core/services/auth.service";
import { UiService } from "../services/ui.service";
import { GoogleTagManagerService } from "@app/shared/google-tag-manager.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit {

  constructor(public authService: AuthService, public uiService: UiService, public gtmService:GoogleTagManagerService) {}

  ngOnInit(): void {

  }

  exitQuote() {
    const isQuoteRated = JSON.parse(sessionStorage.getItem("isQuoteRated"));
    if(isQuoteRated) {
      sessionStorage.setItem("isQuoteRated", JSON.stringify(false));
      location.reload();
    } else {
      this.uiService.exitQuoteFlag = true;
      this.gtmService.popupView("exit_quote")
    }
  }
}
